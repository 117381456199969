<form [formGroup]="form" (keydown.shift.enter)="onControlEnter($event)" (keydown.enter)="onControlEnter($event)" class="dialog-wrap popup">
    <div formArrayName="array">
        <div *ngFor="let formItem of array.controls; let i = index">
            <nb-card [nbSpinner]="isProcessing" class="small-header popup">
                <nb-card-header>
                    <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('Mẫu hợp đồng' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
                </nb-card-header>
                <nb-card-body>
                    <div [formGroup]="formItem">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="row">
                                    <input type="hidden" formControlName="SystemUuid">
                                    <div class="col-sm-12">
                                        <ngx-form-group [formGroup]="formItem" name="Title" label="Common.title" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                            <input type="text" nbInput fullWidth formControlName="Title" placeholder="{{'Common.title' | translate | headtitlecase}}">
                                        </ngx-form-group>
                                    </div>
                                    <div class="col-sm-12">
                                        <ngx-form-group [formGroup]="formItem" name="Name" label="Tên doanh nghiệp" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                            <input type="text" nbInput fullWidth formControlName="Name" placeholder="{{'Tên doanh nghiệp' | translate | headtitlecase}}">
                                        </ngx-form-group>
                                    </div>
                                    <div class="col-sm-12">
                                        <ngx-form-group [formGroup]="formItem" name="ShortName" label="Tên rút gọn/Tên tiếng anh" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                            <input type="text" nbInput fullWidth formControlName="ShortName" placeholder="{{'Tên rút gọn/Tên tiếng anh' | translate | headtitlecase}}">
                                        </ngx-form-group>
                                    </div>
                                    <div class="col-sm-12">
                                        <ngx-form-group [formGroup]="formItem" name="TaxCode" label="Mã số thuế" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                            <input type="text" nbInput fullWidth formControlName="TaxCode" placeholder="{{'Mã số thuế' | translate | headtitlecase}}">
                                        </ngx-form-group>
                                    </div>
                                    <div class="col-sm-12">
                                        <ngx-form-group [formGroup]="formItem" name="Phone" label="Số điện thoại" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                            <input type="text" nbInput fullWidth formControlName="Phone" placeholder="{{'Số điện thoại' | translate | headtitlecase}}">
                                        </ngx-form-group>
                                    </div>
                                    <div class="col-sm-12">
                                        <ngx-form-group [formGroup]="formItem" name="Email" label="Email" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                            <input type="text" nbInput fullWidth formControlName="Email" placeholder="{{'Email' | translate | headtitlecase}}">
                                        </ngx-form-group>
                                    </div>
                                    <div class="col-sm-12">
                                        <ngx-form-group [formGroup]="formItem" name="Address" label="Địa chỉ" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                            <input type="text" nbInput fullWidth formControlName="Address" placeholder="{{'Địa chỉ' | translate | headtitlecase}}">
                                        </ngx-form-group>
                                    </div>
                                    <div class="col-sm-12">
                                        <ngx-form-group [formGroup]="formItem" name="Delegator" label="Người đại diện" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                            <input type="text" nbInput fullWidth formControlName="Delegator" placeholder="{{'Người đại diện' | translate | headtitlecase}}">
                                        </ngx-form-group>
                                    </div>
                                    <div class="col-sm-12">
                                        <ngx-form-group [formGroup]="formItem" name="DelegatorPosition" label="Chức vụ" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                            <input type="text" nbInput fullWidth formControlName="DelegatorPosition" placeholder="{{'Chức vụ' | translate | headtitlecase}}">
                                        </ngx-form-group>
                                    </div>
                                    <div class="col-sm-12">
                                        <ngx-form-group [formGroup]="formItem" name="BankName" label="Ngân hàng" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                            <input type="text" nbInput fullWidth formControlName="BankName" placeholder="{{'Ngân hàng' | translate | headtitlecase}}">
                                        </ngx-form-group>
                                    </div>
                                    <div class="col-sm-12">
                                        <ngx-form-group [formGroup]="formItem" name="BankAccount" label="Số tài khoản ngân hàng" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                            <input type="text" nbInput fullWidth formControlName="BankAccount" placeholder="{{'Số tài khoản ngân hàng' | translate | headtitlecase}}">
                                        </ngx-form-group>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nb-card-body>
                <nb-card-footer>
                    <div class="buttons-row" style="text-align: right;">
                        <button nbButton hero status="primary" (click)="preview(formItem)" [disabled]="!form.valid || isProcessing">
                            <nb-icon nb-icon pack="eva" icon="printer"></nb-icon>{{'Common.print' | translate | headtitlecase}}
                        </button>
                        <button nbButton status="danger" [disabled]="!form.valid || isProcessing" style="float: right;" hero (click)="saveAndClose()">{{'Common.saveAndClose' | translate | headtitlecase}}</button>
                        <button nbButton status="success" [disabled]="!form.valid || isProcessing" style="float: right;" hero (click)="save()">{{'Common.save' | translate | headtitlecase}}</button>
                    </div>
                </nb-card-footer>
            </nb-card>
        </div>
    </div>
</form>