import { Routes } from '@angular/router';
import { ModuleFormComponent } from './module-manager/module-form/module-form.component';
import { ResourceFormComponent } from './resources/resource-form/resource-form.component';
import { ResourceListComponent } from './resources/resource-list/resource-list.component';
import { ModuleListComponent } from './module-manager/module-list/module-list.component';


export const modulesRoutes: Routes = [
  {
    path: 'modules/manager',
    redirectTo: 'modules/manager/list',
    pathMatch: 'full',
  },
  {
    path: 'modules/manager/list',
    // canActivate: [AuthGuardService],
    component: ModuleListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'modules/manager/form',
    // canActivate: [AuthGuardService],
    component: ModuleFormComponent,
  },
  {
    path: 'modules/manager/form/:id',
    // canActivate: [AuthGuardService],
    component: ModuleFormComponent,
  },
  // Resources
  {
    path: 'modules/resources/list',
    // canActivate: [AuthGuardService],
    component: ResourceListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'modules/resources/form',
    // canActivate: [AuthGuardService],
    component: ResourceFormComponent,
  },
  {
    path: 'modules/resources/form/:id',
    // canActivate: [AuthGuardService],
    component: ResourceFormComponent,
  },
];

// @NgModule({
//   imports: [RouterModule.forChild(modulesRoutes)],
//   exports: [RouterModule],
// })
// export class ModulesRoutingModule {
// }
