import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogService, NbToastrService, NbDialogRef, NbThemeService } from '@nebular/theme';
import { ApiService } from '../../../../services/api.service';
import { RootServices } from '../../../../services/root.services';
import { CommonService } from '../../../../services/common.service';
import { WordpressSyncProfileFormComponent } from '../sync-profile-form/sync-profile-form.component';
import { WordpressSyncProfilePreviewComponent } from '../sync-profile-preview/sync-profile-preview.component';
import { AgGridDataManagerListComponent } from '../../../../lib/data-manager/ag-grid-data-manger-list.component';
import { Model } from '../../../../models/model';
import { IGetRowsParams } from 'ag-grid-community';
import { ColDef } from '@ag-grid-community/core';
import { agMakeCommandColDef } from '../../../../lib/custom-element/ag-list/column-define/command.define';
import { agMakeSelectionColDef } from '../../../../lib/custom-element/ag-list/column-define/selection.define';
import { agMakeTextColDef } from '../../../../lib/custom-element/ag-list/column-define/text.define';

@Component({
  selector: 'ngx-sync-profile-list',
  templateUrl: './sync-profile-list.component.html',
  styleUrls: ['./sync-profile-list.component.scss']
})
export class WordpressSyncProfileListComponent extends AgGridDataManagerListComponent<Model, WordpressSyncProfileFormComponent> implements OnInit {

  componentName: string = 'WordpressSyncProfileListComponent';
  formPath = '';
  apiPath = '/wordpress/wp-sync-profiles';
  idKey = ['Code'];
  formDialog = WordpressSyncProfileFormComponent;

  reuseDialog = true;

  // Smart table
  static filterConfig: any;
  static sortConf: any;
  static pagingConf = { page: 1, perPage: 40 };

  constructor(
    public rsv: RootServices,
    public apiService: ApiService,
    public router: Router,
    public cms: CommonService,
    public dialogService: NbDialogService,
    public themeService: NbThemeService,
    public toastService: NbToastrService,
    public _http: HttpClient,
    public ref: NbDialogRef<WordpressSyncProfileListComponent>,
  ) {
    super(rsv, apiService, router, cms, dialogService, toastService, themeService, ref);
  }

  async init() {
    // await this.loadCache();
    return super.init().then(async rs => {
      await this.cms.waitForLanguageLoaded();
      this.columnDefs = this.configSetting([
        {
          ...agMakeSelectionColDef(this.cms),
          headerName: 'ID',
          field: 'Id',
          width: 100,
          valueGetter: 'node.data.Code',
          // sortingOrder: ['desc', 'asc'],
          initialSort: 'desc',
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Sites',
          field: 'Sites',
          width: 700,
          filter: 'agTextColumnFilter',
          autoHeight: true,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Tiến trình',
          field: 'Progress',
          width: 150,
          filter: 'agTextColumnFilter',
          autoHeight: true,
          pinned: 'right',
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Trạng thái',
          field: 'State',
          width: 150,
          pinned: 'right',
          filter: 'agTextColumnFilter',
          autoHeight: true,
        },
        {
          ...agMakeCommandColDef(this, this.cms, true, true, false, [
            {
              name: 'sync',
              label: '',
              title: 'Sync',
              status: 'danger',
              outline: false,
              icon: 'cloud-upload-outline',
              action: async (params) => {
                this.cms.openDialog(WordpressSyncProfilePreviewComponent, {
                  context: {
                    inputId: [params.data.Code],
                  },
                  closeOnEsc: false
                })
                return true;
              }
            }
          ]),
          headerName: 'Lệnh',
          resizable: true,
          // maxWidth: 200,
          // width: 200,
        },
      ] as ColDef[]);
      return rs;
    });
  }

  editing = {};
  rows = [];

  // loadListSetting(): SmartTableSetting {
  //   return this.configSetting({
  //     columns: {
  //       Code: {
  //         title: this.cms.translateText('Common.code'),
  //         type: 'string',
  //         width: '10%',
  //       },
  //       Sites: {
  //         title: 'Sites',
  //         type: 'string',
  //         width: '30%',
  //         valuePrepareFunction: (cell: any, row) => {
  //           return (cell || []).map(m => this.cms.getObjectText(m)).join(', ');
  //         }
  //       },
  //       Progress: {
  //         title: 'Tiến trình',
  //         type: 'string',
  //         width: '40%',
  //         // filterFunction: (value: string, query: string) => this.cms.smartFilter(value, query),
  //       },
  //       State: {
  //         title: 'Phê duyệt',
  //         type: 'string',
  //         width: '10%',
  //       },
  //       // Status: {
  //       //   title: 'Trạng thái',
  //       //   type: 'string',
  //       //   width: '10%',
  //       // },
  //       Sync: {
  //         title: 'Sync',
  //         type: 'custom',
  //         width: '5%',
  //         renderComponent: SmartTableButtonComponent,
  //         onComponentInitFunction: (instance: SmartTableButtonComponent) => {
  //           instance.iconPack = 'eva';
  //           instance.icon = 'cloud-upload-outline';
  //           // instance.label = this.cms.translateText('Common.copy');
  //           instance.display = true;
  //           instance.status = 'danger';
  //           instance.valueChange.subscribe(value => {
  //           });
  //           instance.click.subscribe(async (row: AccBankModel) => {
  //             // await this.apiService.putPromise<any[]>('/wordpress/wp-sync-profiles/' + instance.rowData.Code, { prepare: true }, [
  //             //   {
  //             //     Code: instance.rowData.Code,
  //             //   }
  //             // ]).catch(err => console.log(err));
  //             this.cms.openDialog(WordpressSyncProfilePreviewComponent, {
  //               context: {
  //                 inputId: [instance.rowData.Code],
  //               },
  //               closeOnEsc: false
  //             })
  //           });
  //         },
  //       },
  //     },
  //   });
  // }

  ngOnInit() {
    this.restrict();
    super.ngOnInit();
  }

  // initDataSource() {
  //   const source = super.initDataSource();

  //   // Set DataSource: prepareParams
  //   source.prepareParams = (params: any) => {
  //     // params['includeParent'] = true;
  //     return params;
  //   };

  //   return source;
  // }


  prepareApiParams(params: any, getRowParams: IGetRowsParams, component?: WordpressSyncProfileListComponent) {
    // params['includeContact'] = true;
    // params['includeObject'] = true;
    // params['includeCreator'] = true;
    // params['includeRelativeVouchers'] = true;

    // if (this.cms.getObjectId(this.wordpressService.currentSite$?.value) != 'ALL' && this.cms.getObjectId(this.wordpressService.currentSite$?.value) != 'NONE') {
    //   params['eq_Site'] = this.cms.getObjectId(this.wordpressService.currentSite$?.value);
    // }

    // params['sort_Id'] = 'desc';
    return params;
  }

  /** Implement required */
  openFormDialplog(ids?: string[], onDialogSave?: (newData: Model[]) => void, onDialogClose?: () => void) {
    this.cms.openDialog(WordpressSyncProfileFormComponent, {
      context: {
        inputMode: 'dialog',
        inputId: ids,
        onDialogSave: (newData: Model[]) => {
          if (onDialogSave) onDialogSave(newData);
        },
        onDialogClose: () => {
          if (onDialogClose) onDialogClose();
        },
      },
    });
    return false;
  }

}
