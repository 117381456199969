import { Routes } from "@angular/router";
import { AuthGuardService } from "../../services/auth-guard.service";
import { ContractListComponent } from "./contract/contract-list/contract-list.component";
import { ContractTemplateListComponent } from "./template/contract-template-list/contract-template-list.component";
import { ContractBusinessInfoListComponent } from "./business-info/business-info-list/business-info-list.component";

export const contractRoutes: Routes = [
    //Contract
  {
    path: 'contract/contract/list',
    canActivate: [AuthGuardService],
    component: ContractListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'contract/template/list',
    canActivate: [AuthGuardService],
    component: ContractTemplateListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'contract/business-info/list',
    canActivate: [AuthGuardService],
    component: ContractBusinessInfoListComponent,
    data: {
      reuse: true,
    },
  },
];