import { OtherBusinessVoucherModel, OtherBusinessVoucherDetailModel } from '../../../../models/accounting.model';
import { AccountingOtherBusinessVoucherFormComponent } from '../../other-business-voucher/accounting-other-business-voucher-form/accounting-other-business-voucher-form.component';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogService, NbToastrService, NbDialogRef, NbThemeService } from '@nebular/theme';
import { takeUntil, filter } from 'rxjs/operators';
import { AccountModel } from '../../../../models/accounting.model';
import { ApiService } from '../../../../services/api.service';
import { CommonService } from '../../../../services/common.service';
import { CurrencyPipe } from '@angular/common';
import { AccountingService } from '../../accounting.service';
import { AccountingDetailByObjectReportAgComponent } from '../accounting-detail-by-object-report-ag/accounting-detail-by-object-report-ag.component';
import { RootServices } from '../../../../services/root.services';
import { AgTextCellRenderer } from '../../../../lib/custom-element/ag-list/cell/text.component';
import { ColDef, IGetRowsParams } from '@ag-grid-community/core';
import { AgGridDataManagerListComponent } from '../../../../lib/data-manager/ag-grid-data-manger-list.component';
import { PurchaseOrderVoucherFormComponent } from '../../../purchase/order/purchase-order-voucher-form/purchase-order-voucher-form.component';
import { Model } from '../../../../models/model';
import { AccAccountFormComponent } from '../../acc-account/acc-account-form/acc-account-form.component';
import { agMakeCommandColDef } from '../../../../lib/custom-element/ag-list/column-define/command.define';
import { agMakeCurrencyColDef } from '../../../../lib/custom-element/ag-list/column-define/currency.define';
import { agMakeSelectionColDef } from '../../../../lib/custom-element/ag-list/column-define/selection.define';

@Component({
  selector: 'ngx-accounting-profit-report',
  templateUrl: './accounting-profit-report.component.html',
  styleUrls: ['./accounting-profit-report.component.scss'],
  providers: [
    CurrencyPipe,
  ]
})
export class AccountingProfitReportComponent extends AgGridDataManagerListComponent<Model, PurchaseOrderVoucherFormComponent> implements OnInit {


  componentName: string = 'AccountingProfitReportComponent';
  formPath = '/accounting/account/form';
  apiPath = '/accounting/reports';
  idKey = ['Code'];
  formDialog = AccAccountFormComponent;

  reuseDialog = true;

  // Smart table
  static filterConfig: any;
  static sortConf: any;
  static pagingConf = { page: 1, perPage: 40 };

  totalBalance: { Debit: number, Credit: number } = null;
  tabs: any[];

  constructor(
    public rsv: RootServices,
    public apiService: ApiService,
    public router: Router,
    public cms: CommonService,
    public dialogService: NbDialogService,
    public toastService: NbToastrService,
    public themeService: NbThemeService,
    public _http: HttpClient,
    public ref: NbDialogRef<AccountingProfitReportComponent>,
    public currencyPipe: CurrencyPipe,
    public accountingService: AccountingService,
  ) {
    super(rsv, apiService, router, cms, dialogService, toastService, themeService, ref);

    this.defaultColDef = {
      ...this.defaultColDef,
      cellClass: 'ag-cell-items-center',
    }

    this.pagination = false;
    // this.maxBlocksInCache = 5;
    this.paginationPageSize = 100;
    this.cacheBlockSize = 100;

  }

  async init() {
    // await this.loadCache();
    await this.cms.waitForReady();
    this.tabs = [
      {
        title: 'Liabilities',
        route: '/accounting/report/liabilities',
        icon: 'home',
        // responsive: true, // hide title before `route-tabs-icon-only-max-width` value
      },
      {
        title: 'Receivables',
        route: '/accounting/report/receivables',
      },
      {
        title: 'Users',
        icon: 'person',
        route: './tab1',
      },
      {
        title: 'Orders',
        icon: 'paper-plane-outline',
        responsive: true,
        route: ['./tab2'],
      },
      {
        title: 'Transaction',
        icon: 'flash-outline',
        responsive: true,
        disabled: true,
      },
    ];
    return super.init().then(async rs => {
      this.actionButtonList = this.actionButtonList.filter(f => ['delete', 'edit', 'choose', 'preview'].indexOf(f.name) < 0);
      this.actionButtonList.find(f => f.name === 'refresh').label = this.cms.translateText('Common.refresh');
      const addActionButton = this.actionButtonList.find(f => f.name === 'add');
      if (addActionButton) {
        addActionButton.icon = 'save';
        addActionButton.status = 'primary';
        addActionButton.label = this.cms.translateText('Accounting.profitForward');
        addActionButton.click = () => {

          this.getList(rs => {
            const details: OtherBusinessVoucherDetailModel[] = [];
            let profit = true;
            let profitAmount = 0;
            for (const detail of rs) {
              details.push({
                Description: 'Kết chuyển ' + detail['AccountName'],
                Amount: Math.abs(detail['TailCredit'] - detail['TailDebit']),
                Currency: 'VND',
                DebitAccount: detail['DebitAccount'],
                CreditAccount: detail['CreditAccount'],
              });
              if (detail['DebitAccount'] === '4212') {
                profit = false;
                profitAmount = Math.abs(detail['TailCredit'] - detail['TailDebit']);
              };
              if (detail['CreditAccount'] === '4212') {
                profit = true;
                profitAmount = Math.abs(detail['TailCredit'] - detail['TailDebit']);
              };
            }

            const toDate = this.accountingService?.reportToDate$?.value || new Date();
            this.cms.openDialog(AccountingOtherBusinessVoucherFormComponent, {
              context: {
                showLoadinng: true,
                inputMode: 'dialog',
                // inputId: ids,
                data: [{
                  DateOfVoucher: new Date(toDate.getFullYear(), toDate.getMonth(), toDate.getDate(), 21, 0, 0) as any,
                  Description: 'Kết chuyển lãi/lỗ đến ngày ' + this.cms.datePipe.transform(toDate, 'short') + ' => ' + (profit ? 'Lãi' : 'Lỗ') + ' ' + this.currencyPipe.transform(profitAmount, 'VND'),
                  Details: details,
                }],
                onDialogSave: (newData: OtherBusinessVoucherModel[]) => {
                  if (newData[0]?.Code) {
                    this.cms.previewVoucher('OTHERBUSINESSVOUCHER', newData[0].Code, () => {
                      this.refresh();
                    });
                  }
                },
                onDialogClose: () => {
                },
              },
              closeOnEsc: false,
              closeOnBackdropClick: false,
            });
          });

        };
      }

      // Auto refresh list on reportToDate changed
      this.accountingService?.reportToDate$.pipe(takeUntil(this.destroy$), filter(f => f !== null)).subscribe(toDate => {
        console.log(toDate);
        this.refresh();
      });

      await this.cms.waitForLanguageLoaded();
      this.columnDefs = this.configSetting([
        {
          ...agMakeSelectionColDef(this.cms),
          headerName: 'ID',
          field: 'Id',
          width: 100,
          valueGetter: 'node.data.Account',
          // sortingOrder: ['desc', 'asc'],
          // initialSort: 'desc',
        },
        {
          headerName: this.cms.translateText('Tài khoản'),
          field: 'AccountName',
          width: 512,
          // pinned: 'left',
          cellRenderer: AgTextCellRenderer,
        },
        {
          headerName: this.cms.translateText('Accounting.debitAccount'),
          field: 'DebitAccount',
          width: 200,
          pinned: 'right',
          cellRenderer: AgTextCellRenderer,
        },
        {
          headerName: this.cms.translateText('Accounting.creditAccount'),
          field: 'CreditAccount',
          width: 200,
          pinned: 'right',
          cellRenderer: AgTextCellRenderer,
        },
        // {
        //   headerName: this.cms.translateText('Common.description'),
        //   field: 'AccountName',
        //   width: 512,
        //   // pinned: 'left',
        //   cellRenderer: AgTextCellRenderer
        // },
        {
          ...agMakeCurrencyColDef(this.cms),
          headerName: this.cms.translateText('Accounting.tailAmount'),
          field: 'TailAmount',
          pinned: 'right',
          width: 200,
        },
        {
          ...agMakeCommandColDef(this, this.cms, false, false, false, [
            {
              name: 'REPORT',
              label: 'Chi tiết',
              title: 'Báo cáo chi tiết theo tài khoản',
              status: 'primary',
              outline: true,
              action: async (params: any, buttonConfig?: any) => {
                this.openInstantDetailReport(params.data);
                return true;
              }
            }
          ]),
          headerName: 'Lệnh',
          maxWidth: 100,
          resizable: true,
        },
      ] as ColDef[]);

      return rs;
    });
  }

  editing = {};
  rows = [];

  // loadListSetting(): SmartTableSetting {
  //   return this.configSetting({
  //     actions: false,
  //     columns: {
  //       AccountName: {
  //         title: this.cms.translateText('Common.description'),
  //         type: 'string',
  //         width: '40%',
  //         valuePrepareFunction: (cell: any, row: any) => {
  //           if (row['DebitAccount'] === '4212') return cell + ' => Lỗ';
  //           if (row['CreditAccount'] === '4212') return cell + ' => Lãi';
  //           return cell;
  //         },
  //       },
  //       DebitAccount: {
  //         title: this.cms.translateText('Accounting.debitAccount'),
  //         type: 'string',
  //         width: '10%',
  //       },
  //       CreditAccount: {
  //         title: this.cms.translateText('Accounting.creditAccount'),
  //         type: 'string',
  //         width: '10%',
  //       },
  //       // HeadAmount: {
  //       //   title: this.cms.translateText('Accounting.headAmount'),
  //       //   type: 'acc-currency',
  //       //   width: '10%',
  //       // },
  //       // GenerateAmount: {
  //       //   title: this.cms.translateText('Accounting.generate'),
  //       //   type: 'acc-currency',
  //       //   width: '10%',
  //       // },
  //       TailAmount: {
  //         title: this.cms.translateText('Accounting.tailAmount'),
  //         type: 'acc-currency',
  //         width: '10%',
  //         valuePrepareFunction: (cell: any, row: any) => {
  //           return Math.abs(row['TailDebit'] - row['TailCredit']) as any;
  //         },
  //       },
  //       Preview: {
  //         title: this.cms.translateText('Common.detail'),
  //         type: 'custom',
  //         width: '10%',
  //         class: 'align-right',
  //         renderComponent: SmartTableButtonComponent,
  //         onComponentInitFunction: (instance: SmartTableButtonComponent) => {
  //           instance.iconPack = 'eva';
  //           instance.icon = 'external-link-outline';
  //           instance.display = true;
  //           instance.status = 'primary';
  //           instance.style = 'text-align: right';
  //           instance.class = 'align-right';
  //           instance.title = this.cms.translateText('Common.preview');
  //           instance.label = this.cms.translateText('Common.detail');
  //           instance.valueChange.subscribe(value => {
  //             // instance.icon = value ? 'unlock' : 'lock';
  //             // instance.status = value === 'REQUEST' ? 'warning' : 'success';
  //             // instance.disabled = value !== 'REQUEST';
  //           });
  //           instance.click.pipe(takeUntil(this.destroy$)).subscribe((rowData: any) => {
  //             this.openInstantDetailReport(rowData);
  //           });
  //         },
  //       }
  //     },
  //   });
  // }

  ngOnInit() {
    this.restrict();
    super.ngOnInit();
  }

  // initDataSource() {
  //   const source = super.initDataSource();

  //   // Set DataSource: prepareParams
  //   source.prepareParams = (params: any) => {
  //     params['includeParent'] = true;
  //     params['includeAmount'] = true;
  //     return params;
  //   };

  //   return source;
  // }

  /** Api get funciton */
  executeGet(params: any, success: (resources: AccountModel[]) => void, error?: (e: HttpErrorResponse) => void, complete?: (resp: AccountModel[] | HttpErrorResponse) => void) {
    // params['includeParent'] = true;
    params['reportProfit'] = true;
    params['eq_Accounts'] = '511,515,512,521,632,635,,641,642,623,811,711';
    params['groupBy'] = 'Account';
    // if (this.accountingService?.reportToDate$?.value) {
    const choosedDate = (this.accountingService.reportToDate$.value as Date) || new Date();
    const toDate = new Date(choosedDate.getFullYear(), choosedDate.getMonth(), choosedDate.getDate(), 25, 59, 59, 999);
    params['toDate'] = toDate.toISOString();
    // }

    super.executeGet(params, success, error, complete);
  }

  prepareApiParams(params: any, getRowParams: IGetRowsParams) {

    params['reportProfit'] = true;
    params['eq_Accounts'] = '511,515,512,521,632,635,,641,642,623,811,711';
    params['groupBy'] = 'Account';
    // if (this.accountingService?.reportToDate$?.value) {
    const choosedDate = (this.accountingService.reportToDate$.value as Date) || new Date();
    const toDate = new Date(choosedDate.getFullYear(), choosedDate.getMonth(), choosedDate.getDate(), 25, 59, 59, 999);
    params['toDate'] = toDate.toISOString();
    return params;
  }

  getList(callback: (list: AccountModel[]) => void) {
    super.getList((rs) => {
      // rs.forEach(item => {
      //   item.Content = item.Content.substring(0, 256) + '...';
      // });
      // for (const item of rs) {
      //   if (item['DebitAccount'] === '4212') item['AccountName'] += ' => Lỗ';
      //   if (item['CreditAccount'] === '4212') item['AccountName'] += ' => Lãi';
      // }
      if (callback) callback(rs);
    });
  }

  /** Config for paging */
  protected configPaging() {
    return {
      display: true,
      perPage: 99999,
    };
  }

  openInstantDetailReport(rowData: any) {
    this.cms.openDialog(AccountingDetailByObjectReportAgComponent, {
      context: {
        inputMode: 'dialog',
        // object: rowData.Object,
        accounts: [rowData['DebitAccount'] === '911' ? rowData['CreditAccount'] : rowData['DebitAccount']],
        report: 'reportDetailByAccountAndObject',
        fromDate: null,
        toDate: null,
        includeRowHeader: false,
      },
      closeOnEsc: false,
    })
  }

  async refresh() {
    super.refresh();
  }
  openFormDialplog(ids?: string[], onDialogSave?: (newData: Model[]) => void, onDialogClose?: () => void): void {
    throw new Error('Method not implemented.');
  }
}
