<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="$event.preventDefault()" (keydown.enter)="$event.preventDefault()">
  <div formArrayName="array">
    <div *ngFor="let formItem of array.controls; let i = index">
      <nb-card class="small-header">
        <nb-card-header>
          <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('Module' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
        </nb-card-header>
        <nb-card-body>
          <div [formGroup]="formItem">
            <div class="row">
              <input type="hidden" formControlName="Name_old">
              <div class="col-sm-3">
                <div class="form-group">
                  <label class="label">Tên module</label>
                  <input type="text" nbInput fullWidth formControlName="Name" placeholder="Tên module">
                </div>
              </div>
              <div class="col-sm-9">
                <div class="form-group">
                  <label class="label">Diễn giải</label>
                  <input type="text" nbInput fullWidth formControlName="Description" placeholder="Diễn giải">
                </div>
              </div>
            </div>

            <hr>
            <label class="label">Danh sách component</label>
            <div class="row fit-row form-detail-header">
              <div class="fit-fist-col label column">Stt</div>
              <div class="row fit-content-column">
                <div class="col-sm-4 label column">{{'Common.name' | translate | headtitlecase}}</div>
                <div class="col-sm-4 label column">{{'Common.path' | translate | headtitlecase}}</div>
                <div class="col-sm-4 label column">{{'Common.description' | translate | headtitlecase}}</div>
              </div>
              <div class="fit-last-col column">
                <button class="fit-control-button" nbButton [outline]="true" status="success" (click)="addComponentFormGroup(i)" size="medium" hero>
                  <nb-icon pack="eva" icon="plus-circle-outline"></nb-icon>
                </button>
              </div>
            </div>
            <div formArrayName="Resources" class="form-details">

              <div class="form-detail-item" *ngFor="let resource of getComponents(i).controls; let ic=index" [formGroup]="resource">
                <div class="row fit-row">
                  <div class="fit-fist-col label">
                    <div class="form-group">
                      <input class="fit-sequence" nbInput fullWidth disabled="true" value="{{ ic + 1 }}">
                    </div>
                  </div>
                  <div class="row fit-content-column">
                    <div class="col-sm-12">
                      <div class="row">
                        <div class="col-sm-4">
                          <ngx-form-group [hideLabel]="true" [formGroup]="resource" name="Name" label="Common.name" [array]="getComponents(i)" [index]="ic" [allowCopy]="true" [touchedValidate]="false">
                            <input type="text" nbInput fullWidth formControlName="Name" placeholder="{{'Common.name' | translate | headtitlecase}}">
                          </ngx-form-group>
                        </div>
                        <div class="col-sm-4">
                          <ngx-form-group [hideLabel]="true" [formGroup]="resource" name="Path" label="Common.path" [array]="getComponents(i)" [index]="ic" [allowCopy]="true" [touchedValidate]="false">
                            <input type="text" nbInput fullWidth formControlName="Path" placeholder="{{'Common.path' | translate | headtitlecase}}">
                          </ngx-form-group>
                        </div>
                        <div class="col-sm-4">
                          <ngx-form-group [hideLabel]="true" [formGroup]="resource" name="Description" label="Common.description" [array]="getComponents(i)" [index]="ic" [allowCopy]="true" [touchedValidate]="false">
                            <input type="text" nbInput fullWidth formControlName="Description" placeholder="{{'Common.description' | translate | headtitlecase}}">
                          </ngx-form-group>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="fit-last-col">
                    <div class="form-group" style="text-align: right;">
                      <button class="fit-control-button" nbButton status="danger" hero size="medium" (click)="removeComponentGroup(i, ic)">
                        <nb-icon pack="eva" icon="close-circle-outline"></nb-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr>
            <label class="label">Danh sách resources</label>
            <div class="row fit-row form-detail-header">
              <div class="fit-fist-col label column">Stt</div>
              <div class="row fit-content-column">
                <div class="col-sm-5 label column">{{'Common.name' | translate | headtitlecase}}</div>
                <div class="col-sm-7 label column">{{'Common.description' | translate | headtitlecase}}</div>
              </div>
              <div class="fit-last-col column">
                <button class="fit-control-button" nbButton [outline]="true" status="success" (click)="addResourceFormGroup(i)" size="medium" hero>
                  <nb-icon pack="eva" icon="plus-circle-outline"></nb-icon>
                </button>
              </div>
            </div>
            <div formArrayName="Resources" class="form-details">

              <div class="form-detail-item" *ngFor="let resource of getResources(i).controls; let ir=index" [formGroup]="resource">
                <div class="row fit-row">
                  <div class="fit-fist-col label">
                    <div class="form-group">
                      <input class="fit-sequence" nbInput fullWidth disabled="true" value="{{ ir + 1 }}">
                    </div>
                  </div>
                  <div class="row fit-content-column">
                    <div class="col-sm-12">
                      <div class="row">
                        <div class="col-sm-5">
                          <ngx-form-group [hideLabel]="true" [formGroup]="resource" name="Name" label="Common.name" [array]="getResources(i)" [index]="ir" [allowCopy]="true" [touchedValidate]="false">
                            <input type="text" nbInput fullWidth formControlName="Name" placeholder="{{'Common.name' | translate | headtitlecase}}">
                          </ngx-form-group>
                        </div>
                        <div class="col-sm-7">
                          <ngx-form-group [hideLabel]="true" [formGroup]="resource" name="Description" label="Common.description" [array]="getResources(i)" [index]="ir" [allowCopy]="true" [touchedValidate]="false">
                            <input type="text" nbInput fullWidth formControlName="Description" placeholder="{{'Common.description' | translate | headtitlecase}}">
                          </ngx-form-group>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="fit-last-col">
                    <div class="form-group" style="text-align: right;">
                      <button class="fit-control-button" nbButton status="danger" hero size="medium" (click)="removeResourceGroup(i, ir)">
                        <nb-icon pack="eva" icon="close-circle-outline"></nb-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </nb-card-body>
        <nb-card-footer>
          <div class="buttons-row">
            <button nbButton status="warning" (click)="addFormGroup()" style="float: left;" hero>Thêm module</button>
            <button nbButton status="primary" [disabled]="!form.valid" style="float: right;" hero (click)="saveAndClose()">Lưu & Đóng</button>
            <button nbButton status="success" [disabled]="!form.valid" style="float: right;" hero (click)="save()">Lưu</button>
          </div>
        </nb-card-footer>
      </nb-card>
    </div>
  </div>
</form>