import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogRef, NbDialogService, NbThemeService, NbToastrService } from '@nebular/theme';
import { ApiService } from '../../../../services/api.service';
import { CommonService } from '../../../../services/common.service';
import { AgGridDataManagerListComponent } from '../../../../lib/data-manager/ag-grid-data-manger-list.component';
import { DatePipe } from '@angular/common';
import { IGetRowsParams } from 'ag-grid-community';
import { agMakeSelectionColDef } from '../../../../lib/custom-element/ag-list/column-define/selection.define';
import { agMakeCommandColDef } from '../../../../lib/custom-element/ag-list/column-define/command.define';
import { ColDef } from '@ag-grid-community/core';
import { RootServices } from '../../../../services/root.services';
import { UserModel } from '../../../../models/user.model';
import { agMakeDatetimeColDef } from '../../../../lib/custom-element/ag-list/column-define/datetime.define';
import { AgSelect2Filter } from '../../../../lib/custom-element/ag-list/filter/select2.component.filter';
import { AgTextCellRenderer } from '../../../../lib/custom-element/ag-list/cell/text.component';
import { UserGroupFormComponent } from '../user-group-form/user-group-form.component';

@Component({
  selector: 'ngx-user-group-list',
  templateUrl: './user-group-list.component.html',
  styleUrls: ['./user-group-list.component.scss']
})
export class UserGroupListComponent extends AgGridDataManagerListComponent<UserModel, UserGroupFormComponent> implements OnInit {

  componentName: string = 'UserGroupListComponent';
  formPath = '/user/group/form';
  apiPath = '/user/groups';
  idKey = ['Code'];
  formDialog = UserGroupFormComponent;

  reuseDialog = true;
  static _dialog: NbDialogRef<UserGroupListComponent>;

  // AG-Grid config
  public rowHeight: number = 50;
  // @Input() suppressRowClickSelection = false;

  @Input() gridHeight = '100%';

  constructor(
    public rsv: RootServices,
    public apiService: ApiService,
    public router: Router,
    public cms: CommonService,
    public dialogService: NbDialogService,
    public toastService: NbToastrService,
    public themeService: NbThemeService,
    public ref: NbDialogRef<UserGroupListComponent>,
    public datePipe: DatePipe,
  ) {
    super(rsv, apiService, router, cms, dialogService, toastService, themeService, ref);

    this.defaultColDef = {
      ...this.defaultColDef,
      cellClass: 'ag-cell-items-center',
    }

    this.pagination = false;
    this.maxBlocksInCache = 5;
    this.paginationPageSize = 100;
    this.cacheBlockSize = 100;

  }

  async init() {
    return super.init().then(async state => {

      // const processingMap = AppModule.processMaps['cashVoucher'];
      await this.cms.waitForLanguageLoaded();
      this.columnDefs = this.configSetting([
        {
          ...agMakeSelectionColDef(this.cms),
          headerName: 'Stt',
          field: 'Id',
          width: 100,
          valueGetter: 'node.data.Code',
          initialSort: 'desc',
        },
        {
          headerName: 'ID',
          field: 'Code',
          width: 140,
          filter: 'agTextColumnFilter',
          pinned: 'left',
        },
        {
          headerName: 'Tên',
          field: 'Name',
          width: 300,
          filter: 'agTextColumnFilter',
          // autoHeight: true,
        },
        {
          headerName: 'Mô tả',
          field: 'Description',
          width: 500,
          filter: 'agTextColumnFilter',
          // autoHeight: true,
        },
        {
          headerName: 'Nhóm cha',
          field: 'Parent',
          width: 320,
          // pinned: 'left',
          cellRenderer: AgTextCellRenderer,
          // cellRenderer: (params) => {
          //   if (params?.data) {
          //     return params.data?.ObjectName;
          //   }
          // },
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.makeSelect2AjaxOption('/user/groups', { includeIdText: true }, {
                placeholder: 'Chọn nhóm...', limit: 10, prepareReaultItem: (item) => {
                  return item;
                }
              }),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        {
          ...agMakeCommandColDef(this, this.cms, true, true, false),
          headerName: 'Lệnh',
        },
      ] as ColDef[]);

      return state;
    });
  }

  ngOnInit() {
    super.ngOnInit();
  }

  // @Input() getRowHeight = (params: RowHeightParams<CommercePosOrderModel>) => {
  //   return 123;
  // }

  prepareApiParams(params: any, getRowParams: IGetRowsParams) {
    params['includeParent'] = true;
    return params;
  }

  /** Implement required */
  openFormDialplog(ids?: string[], onDialogSave?: (newData: UserModel[]) => void, onDialogClose?: () => void) {
    this.cms.openDialog(UserGroupFormComponent, {
      context: {
        inputMode: 'dialog',
        inputId: ids,
        onDialogSave: (newData: UserModel[]) => {
          if (onDialogSave) onDialogSave(newData);
        },
        onDialogClose: () => {
          if (onDialogClose) onDialogClose();
        },
      },
    });
    return false;
  }

  onGridReady(params) {
    super.onGridReady(params);
  }
}
