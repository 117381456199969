import { WpSiteModel } from './../../models/wordpress.model';
import { take, filter, takeUntil } from 'rxjs/operators';
import { CommonService } from '../../services/common.service';
import { Injectable } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { NbAuthService } from '@nebular/auth';
import { BehaviorSubject } from 'rxjs';
import { PageModel } from '../../models/page.model';

@Injectable({
  providedIn: 'root'
})
export class WordpressService {

  siteList$ = new BehaviorSubject<WpSiteModel[]>([]);
  currentSite$: BehaviorSubject<string>;

  constructor(
    public cms: CommonService,
    public apiService: ApiService,
    public authService: NbAuthService,
  ) {

    // Load current apge form local store
    let currenPageCache = localStorage.getItem('wordpress.site');
    this.currentSite$ = new BehaviorSubject<string>(currenPageCache == 'null' ? null : currenPageCache);

    // store current page on change
    this.currentSite$.subscribe(value => {
      if(value === null) {
        localStorage.removeItem('wordpress.site');
      } else {
        localStorage.setItem('wordpress.site', value);
      }
    });

    // wait for first authentication success
    this.authService.isAuthenticated().pipe(filter(f => !!f)).subscribe(() => {
      this.apiService.getPromise<PageModel[]>('/wordpress/sites', { onlyIdText: true }).then(pageList => {
        // this.siteList$.next([{ 'id': 'NONE', text: 'None' }, { id: 'ALL', text: 'Tất cả' }, ...pageList]);
        this.siteList$.next(pageList);
        let currentSite = localStorage.getItem('wordpress.site');
        if (!currentSite && pageList.length > 0) {
          currentSite = this.cms.getObjectId(pageList[0]);
        }
        if (currentSite) {
          this.currentSite$.next(currentSite);
        }
      });
    });

  }
}
