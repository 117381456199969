import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { Component, OnDestroy } from "@angular/core";
import { CommonService } from "../../../../../services/common.service";
import { CurrencyPipe, DecimalPipe } from "@angular/common";
import { ICellRendererParams, IRowNode } from "@ag-grid-community/core";
import { FormControl } from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { CurrencyMaskConfig } from "ng2-currency-mask";

@Component({
    selector: 'ag-date-cell-input',
    template: `
      <input type="text" [status]="params.status || 'basic'" [owlDateTime]="DateTime" [owlDateTimeTrigger]="DateTime" nbInput fullWidth [formControl]="inputControl" placeholder="{{'Ngày' | translate | headtitlecase}}">
        <owl-date-time #DateTime [pickerType]="'calendar'"></owl-date-time>
    `,
    providers: []
})
export class AgDateCellInput implements ICellRendererAngularComp, OnDestroy {

    towDigitsInputMask = this.cms.createFloatNumberMaskConfig({
        digitsOptional: false,
        digits: 2
    });
    // curencyFormat: CurrencyMaskConfig = this.cms.getCurrencyMaskConfig();
    inputControl: FormControl = new FormControl();

    protected destroy$: Subject<void> = new Subject<void>();

    constructor(
        public cms: CommonService,
    ) {

    }
    refresh(params: ICellRendererParams): boolean {
        // throw new Error('Method not implemented.');
        if (this.params.onRefresh) {
            this.params.onRefresh(params, this);
        }
        this.inputControl.setValue(params.value, { emitEvent: false });
        return true;
    }
    public params: any;

    agInit(params: any): void {
        this.params = params;
        if (params.onInit) {
            params.onInit(params, this);
        }
        this.inputControl.setValue(this.params.value);
        setTimeout(() => {
            this.inputControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
                this.params.status = 'warning';
                // }
                if (this.params.takeUntilDelay === 0) {
                    this.onChangeHandler(null);
                } else {
                    const nodeId = (this.params.node as IRowNode).id;
                    console.log('node id', nodeId);
                    this.cms.takeUntilCallback('ag-date-stop-typing-' + nodeId, this.params.takeUntilDelay || 1000, () => {
                        this.onChangeHandler(null);
                    });
                }
            });
        }, 500);

    }

    onChangeHandler(event) {
        let value = this.inputControl.value;
        let colId = this.params.column.colId;
        this.params.node.setDataValue(colId, value);
        if (!this.params.changed) {
            this.params.status = 'success';
        }
        return this.params.changed && this.params.changed(value, this.params);
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
