<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="$event.preventDefault()"
  (keydown.enter)="$event.preventDefault()" class="popup">
  <div formArrayName="array">
    <div *ngFor="let formItem of array.controls; let i = index">
      <nb-card class="small-header popup">
        <nb-card-header>
          <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('Wordpress.Sync.title' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>

          <!-- <nav>
            Cập nhật site wordpress
            <button nbButton status="danger" hero size="tiny" (click)="goback()" style="float: right;" title="Trở về">
              <nb-icon pack="eva" icon="close-circle"></nb-icon>
            </button>
            <button nbButton status="success" hero size="tiny" (click)="onFormReload()" style="float: right;"
              title="Tải lại">
              <nb-icon pack="eva" icon="refresh"></nb-icon>
            </button>
            <button nbButton status="warning" hero size="tiny" (click)="onFormUndo()" [disabled]="!canUndo"
              style="float: right;" title="Hoàn tác">
              <nb-icon pack="eva" icon="undo-outline"></nb-icon>
            </button>
          </nav> -->
        </nb-card-header>
        <nb-card-body>
          <div [formGroup]="formItem">
            <div class="row">
              <input type="hidden" formControlName="Code">
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Name'), 'warning')">
                  <label class="label"><span class="valid">Tên (*)</span><span class="invalid">Tên là bắt buộc
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="Name" readonly placeholder="Tên">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Domain'), 'warning')">
                  <label class="label"><span class="valid">Domain</span><span class="invalid">Domain là bắt buộc
                      (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="Domain" readonly placeholder="Domain">
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('SyncCategories'), 'warning')">
                  <label class="label"><span class="valid">Sync Categories</span><span class="invalid">Sync Categories
                      là bắt buộc
                      (*)</span></label>
                  <!-- <ngx-select2 [data]="originSiteCategories[formItem.get('Code').value]" formControlName="SyncCategories" [select2Option]="select2SyncCategoiesOption"></ngx-select2> -->
                  <ngx-ag-list formControlName="SyncCategories"
                  [columnDefs]="originSiteCategoriesColumnDefs[formItem.get('Code').value]"
                  [data]="originSiteCategories[formItem.get('Code').value]"
                  (selectChange)="originSiteCategoryChange($event, formItem.get('Code').value)"></ngx-ag-list>

                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('SyncPages'), 'warning')">
                  <label class="label"><span class="valid">Sync Pages</span><span class="invalid">Sync Pages
                      là bắt buộc
                      (*)</span></label>
                  <ngx-select2 [data]="originSiteSyncPagess[formItem.get('Code').value]" formControlName="SyncPages" [select2Option]="select2SyncCategoiesOption"></ngx-select2>
                </div>
              </div>
            </div>

            <hr>
            <label class="label">Danh sách components</label>
            <div class="row">
              <div class="col-sm-1">STT</div>
              <div class="col-sm-10">
                <div class="row">
                  <div class="col-sm-5">Target Site</div>
                  <div class="col-sm-6">Sync Resouces</div>
                  <div class="col-sm-1">Active</div>
                </div>
              </div>
              <div class="col-sm-1" style="text-align: center;">
                <button nbButton status="success" (click)="addSyncTargetFormGroup(i)" size="tiny"
                  style="width: 100%; margin-bottom: 3px;" hero>
                  <nb-icon pack="eva" icon="plus-circle-outline"></nb-icon>
                </button>
              </div>
            </div>

            <hr>
            <div formArrayName="SyncTargets" class="form-details">
              <div class="form-detail-item" *ngFor="let targetSite of getSyncTargets(i).controls; let ic=index"
                [formGroup]="targetSite">
                <div class="row">
                  <div class="col-sm-1">
                    <div class="form-group">
                      <!-- <label class="label">Stt</label> -->
                      <input nbInput fullWidth disabled="true" value="{{ ic + 1 }}" style="text-align: center;">
                    </div>
                  </div>
                  <div class="col-sm-10">
                    <div class="row">
                      <div class="col-sm-5">
                        <div class="form-group">
                          <label class="label">Target Site</label>
                          <nb-select size="medium" formControlName="TargetSite" fullWidth>
                            <nb-option [value]="">Chọn site</nb-option>
                            <nb-option *ngFor="let site of wpSiteList" [value]="site.Code"> {{ site.Name }}</nb-option>
                          </nb-select>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label class="label">Sync resource</label>
                          <ngx-select2 [data]="wpResourceList" formControlName="Resources"
                            [select2Option]="select2SyncResourceOption"
                            (selectChange)="onAddSyncTargetFormGroup(i, $event)"></ngx-select2>
                        </div>
                      </div>
                      <div class="col-sm-1">
                        <div class="form-group">
                          <label class="label">Active</label>
                          <nb-checkbox formControlName="Active"></nb-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-1">
                    <div class="form-group">
                      <label class="label">CM</label>
                      <button nbButton status="danger" hero size="tiny" (click)="removeSyncTargetGroup(i, ic)"
                        style="width: 100%; margin-bottom: 3px;">
                        <nb-icon pack="eva" icon="close-circle-outline"></nb-icon>
                      </button>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <nb-progress-bar style="margin-bottom: 1rem;"
                      [value]="progressBarMap[targetSite.get('TargetSite').value]?.percent"
                      [status]="progressBarMap[targetSite.get('TargetSite').value]?.status">
                      {{progressBarMap[targetSite.get('TargetSite').value]?.label}}</nb-progress-bar>
                  </div>
                </div>

              </div>
            </div>


          </div>
        </nb-card-body>
        <nb-card-footer>


          <select style="width: 100%;" size="10" #logsEle>
            <option *ngFor="let log of logs">{{log}}</option>
            <option>Nhật ký tiến trình</option>
          </select>
          <br>
          <br>

          <div class="buttons-row" style="text-align: right;">
            <button nbButton status="success" [disabled]="!form.valid" style="float: right;" hero>Lưu</button>
            <button (click)="startCopy(formItem)" nbButton status="danger" [disabled]="!form.valid"
              *ngIf="!isSynchroizing()" style="float: right;" hero>Sync</button>
            <button (click)="stopCopy()" nbButton status="warning" [disabled]="!form.valid" *ngIf="isSynchroizing()"
              style="float: right;" hero>Stop</button>
          </div>
        </nb-card-footer>
      </nb-card>

    </div>
  </div>
</form>
