import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NbToastrService, NbDialogService, NbDialogRef } from "@nebular/theme";
import { DataManagerFormComponent } from "../../../../../../lib/data-manager/data-manager-form.component";
import { ApiService } from "../../../../../../services/api.service";
import { CommonService } from "../../../../../../services/common.service";
import { RootServices } from "../../../../../../services/root.services";
import { AdminProductService } from "../../../../../admin-product/admin-product.service";
import { ReferenceChoosingDialogComponent } from "../../../../../dialog/reference-choosing-dialog/reference-choosing-dialog.component";
import { MobileAppService } from "../../../../../mobile-app/mobile-app.service";
import { CollaboratorService } from "../../../../collaborator.service";
import { Model } from "../../../../../../models/model";
import { CollaboratorPublisherListCommissionReportPrintComponent } from '../list-commission-report-print/list-commission-report-print.component';


@Component({
  selector: 'ngx-publisher-list-commission-report-form',
  // changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './list-commission-report-form.component.html',
  styleUrls: ['./list-commission-report-form.component.scss']
})
export class CollaboratorPublisherListCommissionReportFormComponent extends DataManagerFormComponent<Model> implements OnInit {

  componentName: string = 'CollaboratorPublisherListCommissionReportFormComponent';
  idKey = 'Code';
  apiPath = '/collaborator/report/publisher/list-commissions';
  baseFormUrl = '/collaborator/report/publisher/list-commission/form';
  listUrl = '/collaborator/report/publisher/list-commission/list';

  constructor(
    public rsv: RootServices,
    public activeRoute: ActivatedRoute,
    public router: Router,
    public formBuilder: FormBuilder,
    public apiService: ApiService,
    public toastrService: NbToastrService,
    public dialogService: NbDialogService,
    public cms: CommonService,
    public prds: AdminProductService,
    public ref: NbDialogRef<CollaboratorPublisherListCommissionReportFormComponent>,
    public mobileAppService: MobileAppService,
    public collaboratorService: CollaboratorService,
  ) {
    super(rsv, activeRoute, router, formBuilder, apiService, toastrService, dialogService, cms);

  }

  // getRequestId(callback: (id?: string[]) => void) {
  //   callback(this.inputId);
  // }

  select2OptionForPage = {
    placeholder: 'Chọn trang...',
    allowClear: false,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    keyMap: {
      id: 'id',
      text: 'text',
    },
  };
  getRequestId(callback: (id?: string[]) => void) {
    // callback(this.inputId);
    return super.getRequestId(callback);
  }

  ngOnInit() {
    this.restrict();
    super.ngOnInit();
  }

  async init(): Promise<boolean> {
    return super.init().then(async status => {
      if (this.isDuplicate) {
        // Clear id
        this.id = [];
        this.array.controls.forEach((formItem, index) => {
          formItem.get('Code').setValue('');
          formItem.get('Title').setValue('Copy of: ' + formItem.get('Title').value);
        });
      }

      this.actionButtonList.unshift({
        type: 'button',
        name: 'runAction',
        status: 'danger',
        label: 'Chạy báo cáo',
        title: 'Chạy báo cáo',
        size: 'medium',
        icon: 'play-circle-outline',
        // disabled: () => {
        //   return this.queue.length == 0;
        // },
        click: () => {
          const listId = this.array.controls[0].value?.Code;
          if (!listId) {
            this.cms.showError('Danh sách chưa có ID, hãy lưu trước khi chạy !');
            return;
          }
          this.apiService.putPromise<Model[]>(this.apiPath + '/' + listId, { runReport: true }, [{ Code: listId }]).then(rs => {
            this.cms.showToast('Đã khởi chạy báo cáo, bấm nút refresh trong danh sách báo cáo để theo dỏi các báo cáo đã được tạo', 'Chạy báo cáo', { status: 'info' });
          });
        }
      });

      this.actionButtonList.unshift({
        type: 'button',
        name: 'runAction',
        status: 'warning',
        label: 'Chạy lại báo cáo',
        title: 'Bổ sung thêm các chiết khấu của kỳ trước chưa được tính',
        size: 'medium',
        icon: 'play-circle-outline',
        // disabled: () => {
        //   return this.queue.length == 0;
        // },
        click: () => {
          const listId = this.array.controls[0].value?.Code;
          if (!listId) {
            this.cms.showError('Danh sách chưa có ID, hãy lưu trước khi chạy !');
            return;
          }
          this.apiService.putPromise<Model[]>(this.apiPath + '/' + listId, { reRunReport: true }, [{ Code: listId }]).then(rs => {
            this.cms.showToast('Đã khởi chạy báo cáo, bấm nút refresh trong danh sách báo cáo để theo dỏi các báo cáo đã được tạo', 'Chạy báo cáo', { status: 'info' });
          });
        }
      });

      return status;
    });

  }

  /** Execute api get */
  executeGet(params: any, success: (resources: Model[]) => void, error?: (e: HttpErrorResponse) => void) {
    // params['includeRelativeVouchers'] = true;
    super.executeGet(params, success, error);
  }

  async formLoad(formData: Model[], formItemLoadCallback?: (index: number, newForm: FormGroup, formData: Model) => void) {
    return super.formLoad(formData, async (index, newForm, itemFormData) => {

      // Direct callback
      if (formItemLoadCallback) {
        formItemLoadCallback(index, newForm, itemFormData);
      }
    }).then(rs => {
      return rs;
    });

  }

  makeNewFormGroup(data?: Model): FormGroup {
    if (data) {
      if (data.DateOfReport) {
        data.DateOfReport = new Date(data.DateOfReport);
      }
      if (data.DateOfBegin) {
        data.DateOfBegin = new Date(data.DateOfBegin);
      }
      if (data.DateOfEnd) {
        data.DateOfEnd = new Date(data.DateOfEnd);
      }
    }
    const newForm = this.formBuilder.group({
      Code: [''],
      Page: { disabled: true, value: this.collaboratorService.currentpage$?.value || null },
      Description: ['', Validators.required],
      DateOfBegin: ['', Validators.required],
      DateOfEnd: ['', Validators.required],
    });

    // Add controls allway disabled
    // this.disabledControls.push(newForm.get('ObjectName'));

    if (data) {
      newForm.patchValue(data);
    }
    return newForm;
  }

  checkDisabledFormGroup(formData) {
    if (formData) {
      return !this.isDuplicate && this.cms.getObjectId(formData.State) == 'APPROVED';
    }
    return false;
  }

  onAddFormGroup(index: number, newForm: FormGroup, formData?: Model): void {
    super.onAddFormGroup(index, newForm, formData);
  }
  onRemoveFormGroup(index: number): void {

  }

  // goback(): false {
  //   // super.goback();
  //   if (this.mode === 'page') {
  //     this.router.navigate([this.listUrl]);
  //   } else {
  //     this.ref.close();
  //   }
  //   return false;
  // }

  onUpdatePastFormData(aPastFormData: { formData: any; meta: any; }): void { }
  onUndoPastFormData(aPastFormData: { formData: any; meta: any; }): void { }

  calculatToMoney(detail: FormGroup, source?: string) {
    const toMoney = detail.get('Amount').value;
    return toMoney;
  }

  async preview(formItem: FormGroup) {
    const data: Model = formItem.value;
    this.cms.openDialog(CollaboratorPublisherListCommissionReportPrintComponent, {
      context: {
        showLoadinng: true,
        title: 'Xem trước',
        data: [data],
        idKey: ['Code'],
        onSaveAndClose: (priceReport: Model) => {
          this.saveAndClose();
        },
        onSaveAndPrint: (priceReport: Model) => {
          this.save();
        },
      },
    });
    return false;
  }

  openRelativeVoucher(relativeVocher: any) {
    if (relativeVocher) this.cms.previewVoucher(this.cms.getObjectId(relativeVocher.type), relativeVocher);
    return false;
  }

  openRelativeVoucherChoosedDialog(formGroup: FormGroup) {
    this.cms.openDialog(ReferenceChoosingDialogComponent, {
      context: {
        components: {
          'COMMERCEPOSORDER': { title: 'Đơn hàng POS' },
          'SALES': { title: 'Phiếu bán hàng' },
          'PRICEREPORT': { title: 'Phiếu báo giá' },
        },
        // inputMode: 'dialog',
        onDialogChoose: async (chooseItems: any[], type?: string) => {
          console.log(chooseItems);
          const relationVoucher = formGroup.get('RelativeVouchers');
          const relationVoucherValue: any[] = (relationVoucher.value || []);
          const insertList = [];
          this.onProcessing();

          setTimeout(() => {
            this.onProcessed();
          }, 1000);
        },
      }
    })
    return false;
  }


}
