<form [formGroup]="form" (keydown.shift.enter)="onControlEnter($event)" (keydown.enter)="onControlEnter($event)" class="dialog-wrap popup">
    <div formArrayName="array">
        <div *ngFor="let formItem of array.controls; let i = index">
            <nb-card [nbSpinner]="isProcessing" class="small-header popup">
                <nb-card-header>
                    <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('Mẫu hợp đồng' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
                </nb-card-header>
                <nb-card-body>
                    <div [formGroup]="formItem">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="row">
                                    <input type="hidden" formControlName="Code">
                                    <div class="col-sm-12">
                                        <ngx-form-group [formGroup]="formItem" name="Type" label="Type" [array]="array" [index]="i" [allowCopy]="false" [customIcons]="objectControlIcons" [touchedValidate]="false">
                                            <ngx-select2 formControlName="Type" [select2Option]="select2OptionForType"></ngx-select2>
                                        </ngx-form-group>
                                    </div>
                                    <div class="col-sm-12">
                                        <ngx-form-group [formGroup]="formItem" name="Name" label="Common.name" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                            <input type="text" nbInput fullWidth formControlName="Name" placeholder="{{'Common.name' | translate | headtitlecase}}">
                                        </ngx-form-group>
                                    </div>
                                    <div class="col-sm-12">
                                        <ngx-form-group [formGroup]="formItem" name="Description" label="Common.description" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                            <input type="text" nbInput fullWidth formControlName="Description" placeholder="{{'Common.description' | translate | headtitlecase}}">
                                        </ngx-form-group>
                                    </div>
                                    <div class="col-sm-12">
                                        <ngx-form-group [formGroup]="formItem" name="Content" label="Nội dung" [array]="array" [index]="i" [allowCopy]="false" [touchedValidate]="false">
                                            <ckeditor [editor]="Editor" formControlName="Content" [config]="ckEditorConfig"></ckeditor>
                                        </ngx-form-group>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nb-card-body>
                <nb-card-footer>
                    <div class="buttons-row" style="text-align: right;">
                        <button nbButton hero status="primary" (click)="preview(formItem)" [disabled]="!form.valid || isProcessing">
                            <nb-icon nb-icon pack="eva" icon="printer"></nb-icon>{{'Common.print' | translate | headtitlecase}}
                        </button>
                        <button nbButton status="danger" [disabled]="!form.valid || isProcessing" style="float: right;" hero (click)="saveAndClose()">{{'Common.saveAndClose' | translate | headtitlecase}}</button>
                        <button nbButton status="success" [disabled]="!form.valid || isProcessing" style="float: right;" hero (click)="save()">{{'Common.save' | translate | headtitlecase}}</button>
                    </div>
                </nb-card-footer>
            </nb-card>
        </div>
    </div>
</form>