<div class="dialog-wrap popup" [ngStyle]="{width: width || '90w'}">
  <nb-card [ngStyle]="cardStyle" [nbSpinner]="processing">
    <nb-card-header>{{ title }}</nb-card-header>
    <nb-card-body>
      <div [formGroup]="formGroup" #formEle>
        <div class="row">
          <div class="col-sm-1">Thứ tự</div>
          <div class="col-sm-8">Mô tả</div>
          <div class="col-sm-3">ID</div>
        </div>
        <div formArrayName="array" [sortablejs]="array" [sortablejsOptions]="{ handle: '.sorting-handle' }">
          <div class="row" *ngFor="let formItem of array.controls; let i = index" [formGroup]="formItem">
            <div class="col-sm-1">
              <ngx-form-group [formGroup]="formItem" name="no" label="#" [array]="array" [index]="i" [hideLabel]="true">
                <input type="text" nbInput fullWidth formControlName="no" placeholder="Move" value="{{i+1}}" class="sorting-handle" style="cursor: move;">
              </ngx-form-group>
            </div>
            <div class="col-sm-8">
              <ngx-form-group [formGroup]="formItem" name="text" label="text" [array]="array" [index]="i" [hideLabel]="true">
                <input type="text" nbInput fullWidth formControlName="text" placeholder="Text...">
              </ngx-form-group>
            </div>
            <div class="col-sm-3">
              <ngx-form-group [formGroup]="formItem" name="id" label="ID" [array]="array" [index]="i" [hideLabel]="true">
                <input type="text" nbInput fullWidth formControlName="id" placeholder="ID...">
              </ngx-form-group>
            </div>
            <!-- <div [class]="control.class || 'col-sm-12'" *ngFor="let control of controls">
                <div class="form-group" [attr.state]="formGroup.controls[control.name] && formGroup.controls[control.name].status == 'INVALID' ? 'warning' : ''">
                  <label class="label">
                      <span>{{ control.label }}</span>
                      &nbsp;
                      <span *ngIf="formGroup.controls[control.name].errors" class="invalid">({{ errorMap[formGroup.controls[control.name].errors.required && 'required']}})</span>
                  </label>
                  <input [name]="control.name" *ngIf="!control.type || control.type == 'text'" type="text" nbInput fullWidth [formControlName]="control.name" [placeholder]="control.placeholder">
                  <input [name]="control.name" *ngIf="control.type == 'currency'" type="text" nbInput fullWidth [formControlName]="control.name" [placeholder]="control.placeholder" currencyMask [options]="curencyFormat">
                  <input [name]="control.name" *ngIf="control.type == 'number'" type="text" nbInput fullWidth [formControlName]="control.name" [placeholder]="control.placeholder" [inputMask]="numberFormat">
                  <ngx-file-input *ngIf="control.type == 'file'" [name]="control.name" [alowUploadByLink]="true"></ngx-file-input>
                  <textarea style="max-height: 100px" class="scrollable-container" [name]="control.name" *ngIf="control.type == 'textarea'" nbInput fullWidth [formControlName]="control.name" [placeholder]="control.placeholder"></textarea>
                  <ngx-select2 [name]="control.name" [formControlName]="control.name" *ngIf="control.type == 'select2'" [select2Option]="control.option"></ngx-select2>
                  <ng-container *ngIf="control.type == 'datetime'">
                    <input type="text" [owlDateTime]="DateTimePicker" [owlDateTimeTrigger]="DateTimePicker" nbInput fullWidth [name]="control.name" [formControlName]="control.name" [placeholder]="control.placeholder">
                    <owl-date-time #DateTimePicker></owl-date-time>
                  </ng-container>
                  <ng-container *ngIf="control.type == 'date'">
                    <input type="text" [owlDateTime]="DateTimePicker" [owlDateTimeTrigger]="DateTimePicker" nbInput fullWidth [name]="control.name" [formControlName]="control.name" [placeholder]="control.placeholder">
                    <owl-date-time #DateTimePicker [pickerType]="'calendar'"></owl-date-time>
                  </ng-container>
                </div>
              </div> -->
          </div>

        </div>

      </div>
    </nb-card-body>
    <nb-card-footer>
      <div class="buttons-row">
        <button [disabled]="processing || !formGroup.valid" *ngFor="let item of actions" nbButton hero [status]="item?.status" (click)="onAction(item, formGroup) && dismiss()" [disabled]="checkButtonDisabled(item, formGroup)">{{ item?.label }}</button>
      </div>
    </nb-card-footer>
  </nb-card>
</div>