import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogService, NbToastrService, NbDialogRef, NbThemeService } from '@nebular/theme';
import { filter, takeUntil } from 'rxjs/operators';
import { AccountModel } from '../../../../models/accounting.model';
import { ApiService } from '../../../../services/api.service';
import { CommonService } from '../../../../services/common.service';
import { AccAccountFormComponent } from '../../acc-account/acc-account-form/acc-account-form.component';
import { AccountingService } from '../../accounting.service';
import { AccountingReceivablesFromCustomersReportPrintComponent } from '../print/accounting-receivables-from-customers-report-print/accounting-receivables-from-customers-report-print.component';
import { AccountingObjectCashFlowReportPrintComponent } from '../print/accounting-object-cash-flow-report-print/accounting-object-cash-flow-report-print.component';
import { AccountingDetailByObjectReportAgComponent } from '../accounting-detail-by-object-report-ag/accounting-detail-by-object-report-ag.component';
import { RootServices } from '../../../../services/root.services';
import { AgGridDataManagerListComponent } from '../../../../lib/data-manager/ag-grid-data-manger-list.component';
import { PurchaseOrderVoucherFormComponent } from '../../../purchase/order/purchase-order-voucher-form/purchase-order-voucher-form.component';
import { Model } from '../../../../models/model';
import { ColDef, IGetRowsParams } from '@ag-grid-community/core';
import { AgTextCellRenderer } from '../../../../lib/custom-element/ag-list/cell/text.component';
import { agMakeCommandColDef } from '../../../../lib/custom-element/ag-list/column-define/command.define';
import { agMakeCurrencyColDef } from '../../../../lib/custom-element/ag-list/column-define/currency.define';
import { agMakeSelectionColDef } from '../../../../lib/custom-element/ag-list/column-define/selection.define';
import { AgSelect2Filter } from '../../../../lib/custom-element/ag-list/filter/select2.component.filter';

@Component({
  selector: 'ngx-accounting-cash-flow-report',
  templateUrl: './accounting-cash-flow-report.component.html',
  styleUrls: ['./accounting-cash-flow-report.component.scss']
})
export class AccountingCashFlowReportComponent extends AgGridDataManagerListComponent<Model, PurchaseOrderVoucherFormComponent> implements OnInit {

  componentName: string = 'AccountingCashFlowComponent';
  formPath = '/accounting/account/form';
  apiPath = '/accounting/reports';
  idKey = ['Object'];
  formDialog = AccAccountFormComponent;

  reuseDialog = true;

  // Smart table
  static filterConfig: any;
  static sortConf: any;
  static pagingConf = { page: 1, perPage: 40 };

  totalBalance: { Debit: number, Credit: number } = null;
  tabs: any[];

  constructor(
    public rsv: RootServices,
    public apiService: ApiService,
    public router: Router,
    public cms: CommonService,
    public dialogService: NbDialogService,
    public toastService: NbToastrService,
    public themeService: NbThemeService,
    public _http: HttpClient,
    public ref: NbDialogRef<AccountingCashFlowReportComponent>,
    public accountingService: AccountingService,
  ) {
    super(rsv, apiService, router, cms, dialogService, toastService, themeService, ref);

    this.defaultColDef = {
      ...this.defaultColDef,
      cellClass: 'ag-cell-items-center',
    }

    this.pagination = false;
    // this.maxBlocksInCache = 5;
    this.paginationPageSize = 100;
    this.cacheBlockSize = 100;
  }

  async init() {
    // await this.loadCache();
    await this.cms.waitForReady();
    this.tabs = [
      {
        title: 'Liabilities',
        route: '/accounting/report/liabilities',
        icon: 'home',
        // responsive: true, // hide title before `route-tabs-icon-only-max-width` value
      },
      {
        title: 'Receivables',
        route: '/accounting/report/receivables',
      },
      {
        title: 'Users',
        icon: 'person',
        route: './tab1',
      },
      {
        title: 'Orders',
        icon: 'paper-plane-outline',
        responsive: true,
        route: ['./tab2'],
      },
      {
        title: 'Transaction',
        icon: 'flash-outline',
        responsive: true,
        disabled: true,
      },
    ];
    return super.init().then(async rs => {
      this.actionButtonList = this.actionButtonList.filter(f => ['delete', 'edit', 'add', 'choose'].indexOf(f.name) < 0);
      this.actionButtonList.find(f => f.name === 'refresh').label = null;
      const summaryReportBtn = this.actionButtonList.find(f => f.name == 'preview');
      summaryReportBtn.label = summaryReportBtn.title = 'In báo cáo';
      summaryReportBtn.icon = 'printer';
      summaryReportBtn.status = 'info';
      summaryReportBtn.disabled = () => true;
      summaryReportBtn.click = () => {
        this.cms.openDialog(AccountingReceivablesFromCustomersReportPrintComponent, {
          context: {
            showLoadinng: true,
            // title: 'Xem trước',
            mode: 'print',
            id: ['all']
          },
        });
      };

      // const detailsReportBtn = {...summaryReportBtn};
      // detailsReportBtn.status = 'primary';
      // detailsReportBtn.name = 'detailReport';
      // detailsReportBtn.label = detailsReportBtn.title = 'In báo cáo chi tiết';
      // detailsReportBtn.disabled = () => this.selectedIds.length <= 0;
      // detailsReportBtn.click = () => {
      //   this.cms.openDialog(AccountingReceivablesFromCustomersDetailsReportPrintComponent, {
      //     context: {
      //       showLoadinng: true,
      //       // title: 'Xem trước',
      //       mode: 'print',
      //       id: ['all'],
      //       objects: this.selectedIds,
      //     },
      //   });
      // };
      // this.actionButtonList.unshift(detailsReportBtn);

      const vouchersReportBtn = { ...summaryReportBtn };
      vouchersReportBtn.status = 'primary';
      vouchersReportBtn.name = 'vouchersReport';
      vouchersReportBtn.label = vouchersReportBtn.title = 'In đối soát công nợ';
      vouchersReportBtn.disabled = () => this.selectedIds.length <= 0;
      vouchersReportBtn.click = () => {
        this.cms.openDialog(AccountingObjectCashFlowReportPrintComponent, {
          context: {
            showLoadinng: true,
            // title: 'Xem trước',
            mode: 'print',
            id: ['all'],
            objects: this.selectedIds,
            accounts: '131,331,334'
          },
        });
      };
      this.actionButtonList.unshift(vouchersReportBtn);

      // const printDebtConfirmBtn = {...summaryReportBtn};
      // printDebtConfirmBtn.status = 'danger';
      // printDebtConfirmBtn.name = 'detailReport';
      // printDebtConfirmBtn.label = printDebtConfirmBtn.title = 'In phiếu xác nhận công nợ';
      // printDebtConfirmBtn.click = () => {
      //   this.cms.openDialog(AccountingReceivablesFromCustomersReportPrintComponent, {
      //     context: {
      //       showLoadinng: true,
      //       // title: 'Xem trước',
      //       mode: 'print',
      //       id: ['all']
      //     },
      //   });
      // };
      // this.actionButtonList.unshift(printDebtConfirmBtn);

      // Auto refresh list on reportToDate changed
      this.accountingService?.reportToDate$.pipe(takeUntil(this.destroy$), filter(f => f !== null)).subscribe(toDate => {
        console.log(toDate);
        this.refresh();
      });
      this.accountingService?.reportFromDate$.pipe(takeUntil(this.destroy$), filter(f => f !== null)).subscribe(fromDate => {
        console.log(fromDate);
        this.refresh();
      });

      await this.cms.waitForLanguageLoaded();
      this.columnDefs = this.configSetting([
        {
          ...agMakeSelectionColDef(this.cms),
          headerName: 'ID',
          field: 'Id',
          width: 100,
          valueGetter: 'node.data.Account',
          // sortingOrder: ['desc', 'asc'],
          // initialSort: 'desc',
        },
        {
          headerName: this.cms.translateText('Common.contact'),
          field: 'Object',
          width: 320,
          // pinned: 'left',
          cellRenderer: (params) => {
            if(params?.data) {
              return params.data?.ObjectName;
            }
          },
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.makeSelect2AjaxOption('/contact/contacts', { includeIdText: true, includeGroups: true, sort_SearchRank: 'desc' }, {
                placeholder: 'Chọn liên hệ...', limit: 10, prepareReaultItem: (item) => {
                  item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'] + '' + (item['Groups'] ? (' (' + item['Groups'].map(g => g.text).join(', ') + ')') : '');
                  return item;
                }
              }),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        // {
        //   headerName: this.cms.translateText('Common.description'),
        //   field: 'AccountName',
        //   width: 512,
        //   // pinned: 'left',
        //   cellRenderer: AgTextCellRenderer
        // },
        {
          ...agMakeCurrencyColDef(this.cms),
          headerName: 'Nợ ĐK',
          field: 'HeadDebit',
          pinned: 'right',
          width: 150,
        },
        {
          ...agMakeCurrencyColDef(this.cms),
          headerName: 'Có ĐK',
          field: 'HeadCredit',
          pinned: 'right',
          width: 150,
        },
        {
          ...agMakeCurrencyColDef(this.cms),
          headerName: 'PS.Nợ',
          field: 'GenerateDebit',
          pinned: 'right',
          width: 150,
        },
        {
          ...agMakeCurrencyColDef(this.cms),
          headerName: 'PS.Có',
          field: 'GenerateDebit',
          pinned: 'right',
          width: 150,
        },
        {
          ...agMakeCurrencyColDef(this.cms),
          headerName: 'Nợ CK',
          field: 'TailDebit',
          pinned: 'right',
          width: 150,
        },
        {
          ...agMakeCurrencyColDef(this.cms),
          headerName: 'Có CK',
          field: 'TailCredit',
          pinned: 'right',
          width: 150,
        },
        {
          ...agMakeCommandColDef(this, this.cms, false, false, false, [
            {
              name: 'REPORT',
              label: 'Chi tiết',
              title: 'Báo cáo chi tiết theo tài khoản',
              status: 'primary',
              outline: true,
              action: async (params: any, buttonConfig?: any) => {
                this.openInstantDetailReport(params.data);
                return true;
              }
            }
          ]),
          headerName: 'Lệnh',
          maxWidth: 100,
          resizable: true,
        },
      ] as ColDef[]);

      return rs;
    });
  }

  editing = {};
  rows = [];

  // loadListSetting(): SmartTableSetting {
  //   return this.configSetting({
  //     actions: false,
  //     columns: {
  //       Object: {
  //         title: this.cms.translateText('Common.contact'),
  //         type: 'string',
  //         width: '30%',
  //         valuePrepareFunction: (cell: any, row: any) => {
  //           return this.cms.getObjectId(row.Object) + ' - ' + row.ObjectName;
  //         },
  //         filter: {
  //           type: 'custom',
  //           component: SmartTableSelect2FilterComponent,
  //           config: {
  //             delay: 0,
  //             condition: 'eq',
  //             select2Option: {
  //               ...this.cms.makeSelect2AjaxOption('/contact/contacts', {includeIdText: true, includeGroups: true}, { placeholder: 'Chọn liên hệ...', limit: 10, prepareReaultItem: (item) => {
  //                 item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'] + '' + (item['Groups'] ? (' (' + item['Groups'].map(g => g.text).join(', ') + ')') : '');
  //                 return item;
  //               }}),
  //               multiple: true,
  //               logic: 'OR',
  //               allowClear: true,
  //             },
  //           },
  //         },
  //       },
  //       HeadDebit: {
  //         title: '[' + this.cms.translateText('Accounting.headDebit'),
  //         type: 'acc-currency',
  //         width: '10%',
  //       },
  //       HeadCredit: {
  //         title: this.cms.translateText('Accounting.headCredit') + ']',
  //         type: 'acc-currency',
  //         width: '10%',
  //       },
  //       GenerateDebit: {
  //         title: '[' + this.cms.translateText('Accounting.debitGenerate'),
  //         type: 'acc-currency',
  //         width: '10%',
  //       },
  //       GenerateCredit: {
  //         title: this.cms.translateText('Accounting.creditGenerate') + ']',
  //         type: 'acc-currency',
  //         width: '10%',
  //       },
  //       TailDebit: {
  //         title: '[' + this.cms.translateText('Accounting.tailDebit'),
  //         type: 'acc-currency',
  //         width: '10%',
  //       },
  //       TailCredit: {
  //         title: this.cms.translateText('Accounting.tailCredit') + ']',
  //         type: 'acc-currency',
  //         width: '10%',
  //       },
  //       Preview: {
  //         title: this.cms.translateText('Common.detail'),
  //         type: 'custom',
  //         width: '10%',
  //         class: 'align-right',
  //         renderComponent: SmartTableButtonComponent,
  //         onComponentInitFunction: (instance: SmartTableButtonComponent) => {
  //           instance.iconPack = 'eva';
  //           instance.icon = 'external-link-outline';
  //           instance.display = true;
  //           instance.status = 'primary';
  //           instance.style = 'text-align: right';
  //           instance.class = 'align-right';
  //           instance.title = this.cms.translateText('Common.preview');
  //           instance.label = this.cms.translateText('Common.detail');
  //           instance.valueChange.subscribe(value => {
  //             // instance.icon = value ? 'unlock' : 'lock';
  //             // instance.status = value === 'REQUEST' ? 'warning' : 'success';
  //             // instance.disabled = value !== 'REQUEST';
  //           });
  //           instance.click.pipe(takeUntil(this.destroy$)).subscribe((rowData: any) => {
  //             this.openInstantDetailReport(rowData);
  //           });
  //         },
  //       }
  //     },
  //   });
  // }

  ngOnInit() {
    this.restrict();
    super.ngOnInit();
  }

  /** Api get funciton */
  // executeGet(params: any, success: (resources: AccountModel[]) => void, error?: (e: HttpErrorResponse) => void, complete?: (resp: AccountModel[] | HttpErrorResponse) => void) {
  //   params['reportCashFlow'] = true;
  //   const choosedFromDate = (this.accountingService.reportFromDate$.value as Date) || new Date();
  //   const fromDate = new Date(choosedFromDate.getFullYear(), choosedFromDate.getMonth(), choosedFromDate.getDate(), 0, 0, 0, 0);

  //   const choosedToDate = (this.accountingService.reportToDate$.value as Date) || new Date();
  //   const toDate = new Date(choosedToDate.getFullYear(), choosedToDate.getMonth(), choosedToDate.getDate(), 23, 59, 59, 999);

  //   params['toDate'] = toDate.toISOString();
  //   params['fromDate'] = fromDate.toISOString();
  //   super.executeGet(params, success, error, complete);
  // }


  prepareApiParams(params: any, getRowParams: IGetRowsParams) {

    params['includeColumnHeader'] = true;
    params['eq_Accounts'] = '131,331,3341,3348';
    params['groupBy'] = 'Object';

    const choosedFromDate = (this.accountingService.reportFromDate$.value as Date) || new Date();
    const fromDate = new Date(choosedFromDate.getFullYear(), choosedFromDate.getMonth(), choosedFromDate.getDate(), 0, 0, 0, 0);

    const choosedToDate = (this.accountingService.reportToDate$.value as Date) || new Date();
    const toDate = new Date(choosedToDate.getFullYear(), choosedToDate.getMonth(), choosedToDate.getDate(), 23, 59, 59, 999);

    params['toDate'] = toDate.toISOString();
    params['fromDate'] = fromDate.toISOString();
    return params;
  }

  // initDataSource() {
  //   const source = super.initDataSource();
  //   // Set DataSource: prepareParams
  //   source.prepareParams = (params: any) => {

  //     params['includeColumnHeader'] = true;
  //     params['eq_Accounts'] = '131,331,3341,3348';
  //     params['groupBy'] = 'Object';

  //   const choosedFromDate = (this.accountingService.reportFromDate$.value as Date) || new Date();
  //   const fromDate = new Date(choosedFromDate.getFullYear(), choosedFromDate.getMonth(), choosedFromDate.getDate(), 0, 0, 0, 0);

  //   const choosedToDate = (this.accountingService.reportToDate$.value as Date) || new Date();
  //   const toDate = new Date(choosedToDate.getFullYear(), choosedToDate.getMonth(), choosedToDate.getDate(), 23, 59, 59, 999);

  //   params['toDate'] = toDate.toISOString();
  //   params['fromDate'] = fromDate.toISOString();

  //     return params;
  //   };

  //   return source;
  // }

  getList(callback: (list: AccountModel[]) => void) {
    super.getList((rs) => {

      for (const item of rs) {
        const amount = item['HeadDebit'] - item['HeadCredit'] + item['GenerateDebit'] - item['GenerateCredit'];
        if (amount > 0) {
          item['TailDebit'] = amount;
          item['TailCredit'] = 0;
        } else {
          item['TailDebit'] = 0;
          item['TailCredit'] = -amount;
        }
      }

      if (callback) callback(rs);
    });
  }

  /** Config for paging */
  // protected configPaging() {
  //   return {
  //     display: true,
  //     perPage: 99999,
  //   };
  // }

  async refresh() {
    super.refresh();
  }

  openInstantDetailReport(rowData: any) {
    this.cms.openDialog(AccountingDetailByObjectReportAgComponent, {
      context: {
        inputMode: 'dialog',
        object: rowData.Object,
        accounts: ['131', '331', '334'],
        fromDate: null,
        toDate: null,
        balance: 'both',
        report: 'reportVoucherByAccountAndObject',
        // groupBy: '',
        // includeRowHeader: false,
        detailReportComponent: AccountingObjectCashFlowReportPrintComponent,
      },
      closeOnEsc: false,
    })
  }
  openFormDialplog(ids?: string[], onDialogSave?: (newData: Model[]) => void, onDialogClose?: () => void): void {
    throw new Error('Method not implemented.');
  }
}
