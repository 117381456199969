import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DataManagerFormComponent } from '../../../../lib/data-manager/data-manager-form.component';
import { UserModel } from '../../../../models/user.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../../../services/api.service';
import { NbToastrService, NbDialogService, NbDialogRef } from '@nebular/theme';
import { CommonService } from '../../../../services/common.service';
import { UserGroupModel } from '../../../../models/user-group.model';
import { HttpErrorResponse } from '@angular/common/http';
import { RootServices } from '../../../../services/root.services';
import { DialogFormComponent } from '../../../dialog/dialog-form/dialog-form.component';
import { Select2Option } from '../../../../lib/custom-element/select2/select2.component';
import { Select2SelectionObject } from '../../../../../vendor/ng2select2/lib/ng2-select2.interface';

@Component({
  selector: 'ngx-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
})
export class UserFormComponent extends DataManagerFormComponent<UserModel> implements OnInit {

  componentName: string = 'UserFormComponent';
  idKey = 'Code';
  apiPath = '/user/users';
  baseFormUrl = '/users/user-manager/form';

  groupList: UserGroupModel[];
  // select2OptionForGroups: Select2Option = {
  //   placeholder: 'Chọn nhóm...',
  //   allowClear: true,
  //   width: '100%',
  //   dropdownAutoWidth: true,
  //   minimumInputLength: 0,
  //   multiple: true,
  //   keyMap: {
  //     id: 'Code',
  //     text: 'Name',
  //   },
  //   // formatResult: (object: any, container: JQuery, query: any, escapeMarkup: (markup: string) => string) => {
  //   //   return object;
  //   // },
  //   templateResult: (object: Select2SelectionObject) => {
  //     if (object['Name']) {
  //       return object['Name'] + ' - ' + object['Description'];
  //     }
  //     return this.cms.getObjectText(object);
  //   }
  // };
  select2OptionForGroups: Select2Option = {
    ...this.cms.makeSelect2AjaxOption('/user/groups', { inludeIdText: true }, {
      prepareReaultItem: (item) => {
        // item.text = item.Name + ' - ' + item.Description;
        return item;
      }
    }),
    keyMap: {
      id: 'id',
      text: 'text',
    },
    multiple: true,
    templateResult: (object: Select2SelectionObject) => {
      if (object['Name']) {
        return object['Name'] + ' - ' + object['Description'];
      }
      return object?.text || object;
    }
  };

  templateList = [
    {
      id: 'ALLINONECOMMERCE',
      text: 'All in One Thương mại',
      groups: [
        'GENERAL',
        'CHAT',
        'CHATROOMMANAGER',
        'IVOIPSUBADMIN',
        'ACCOUNTANT',
        'PURCHASER',
        'STOKER',
        'SALER',
        'COLLAORDERSUPPORT',
        'COLLABORATORSTOCKER',
        'COLLABORATORTRANSPORT',
        'COLLABORATORDEPLOYMENT',
        'CLBRTSALE',
        'CLBRTPUBLISHERSUPPORTER',
        'DEPLOYMENTUSER',
        'PRODUCTINFO',
        'CONTACT',
        'WORDPRESS',
      ]
    },
    {
      id: 'ALLINONECOMMERCEMANAGER',
      text: 'All in One Thương mại - QL',
      groups: [
        'GENERAL',
        'CHAT',
        'CHATROOMMANAGER',
        'IVIOPADMIN',
        'ACCOUNTINGMANAGER',
        'PURCHASEMANAGER',
        'STOCKEMANAGER',
        'SALEMANAGER',
        'DEPLOYMENTMANAGER',
        'COLLABORATORMANAGER',
        'PRODUCTMANAGER',
        'CONTACTMANAGER',
        'WORDPRESSMANAGER',
      ]
    },
  ];

  constructor(
    public rsv: RootServices,
    public activeRoute: ActivatedRoute,
    public router: Router,
    public formBuilder: FormBuilder,
    public apiService: ApiService,
    public toastrService: NbToastrService,
    public dialogService: NbDialogService,
    public cms: CommonService,
    public ref: NbDialogRef<UserFormComponent>,
  ) {
    super(rsv, activeRoute, router, formBuilder, apiService, toastrService, dialogService, cms);
  }

  ngOnInit() {
    this.restrict();
    super.ngOnInit();
  }

  async init() {
    this.groupList = (await this.apiService.getPromise<UserGroupModel[]>('/user/groups', { includeIdText: true, limit: 'nolimit' }));
    return super.init().then(status => {


      this.actionButtonList.unshift({
        type: 'button',
        name: 'applyUserTemplate',
        status: 'info',
        label: 'Điền theo mẫu',
        title: 'Điền thông tin người dùng theo mẫu sẵn có',
        size: 'medium',
        icon: 'checkmark-square-outline',
        click: () => {
          this.cms.openDialog(DialogFormComponent, {
            context: {
              title: 'Tính doanh thu bán lẻ',
              width: '700px',
              onInit: async (form, dialog) => {
                // const reatilRevenue = form.get('RetailRevenue');
                // form.get('RealCash').valueChanges.pipe(takeUntil(this.destroy$)).subscribe(realCashValue => {
                //   reatilRevenue.setValue(realCashValue - rs[0]['TailAmount']);
                // });
                return true;
              },
              controls: [
                {
                  name: 'Template',
                  label: 'Mẫu người dùng',
                  placeholder: 'Chọn mẫu...',
                  type: 'select2',
                  // initValue: this.sheets[0],
                  // focus: true,
                  option: {
                    data: this.templateList,
                    placeholder: 'Chọn mẫu...',
                    allowClear: true,
                    width: '100%',
                    dropdownAutoWidth: true,
                    minimumInputLength: 0,
                    withThumbnail: false,
                    keyMap: {
                      id: 'id',
                      text: 'text',
                    },
                    // multiple: false,
                    // closeOnSelect: false,
                    // allowHtml: true,
                    // tags: true,
                    // templateResult: (d) => { return d.html ? $(`<span>${d.html}</span>`) : d.text; },
                    // templateSelection: (d) => { return d.text; },
                  }
                },
              ],
              actions: [
                {
                  label: 'Trở về',
                  icon: 'back',
                  status: 'basic',
                  action: async () => { return true; },
                },
                {
                  label: 'Áp dụng',
                  icon: 'generate',
                  status: 'success',
                  action: async (form: FormGroup) => {
                    // console.log(rs);
                    // detailForm.get('Price').setValue(form.get('RealCash').value - rs[0]['TailAmount']);
                    // this.toMoney(parentForm, detail, 'Product');
                    const template = form.get('Template').value;
                    if (template) {
                      for (const i in this.array.controls) {
                        this.array.controls[i].get('Groups').setValue([]);
                        this.array.controls[i].get('Groups').setValue(template.groups.map(m => this.groupList.find(f => f.id == m)));
                      }
                    } else {
                      this.cms.showError('Bạn chưa chọn mẫu');
                      return false;
                    }
                    return true;
                  },
                },
              ],
            },
          });
        }
      });

      return status;
    });
  }

  /** Get form data by id from api */
  getFormData(callback: (data: UserModel[]) => void) {
    this.apiService.get<UserModel[]>(this.apiPath, { id: this.id, multi: true, includeGroups: true },
      data => callback(data),
    ), (e: HttpErrorResponse) => {
      this.onError(e);
    };
  }

  makeNewFormGroup(data?: UserModel): FormGroup {
    const newForm = this.formBuilder.group({
      Code_old: [''],
      Code: [''],
      Username: ['', Validators.required],
      Password: [''],
      Name: ['', Validators.required],
      Phone: [''],
      Email: [''],
      Groups: [''],
    });
    if (data) {
      data[this.idKey + '_old'] = data.Code;
      // data['Groups'] = data['Groups'].map(item => {
      //   return item.Code;
      // });
      newForm.patchValue(data);
    }
    return newForm;
  }

  onAddFormGroup(index: number, newForm: FormGroup, formData?: UserModel): void {
    super.onAddFormGroup(index, newForm, formData);
  }
  onRemoveFormGroup(index: number): void { }
  // goback(): false {
  //   this.router.navigate(['/users/user-manager/list']);
  //   return false;
  // }
  goback(): false {
    super.goback();
    if (this.mode === 'page') {
      this.router.navigate(['/users/user-manager/list']);
    } else {
      this.ref.close();
      // this.onDialogClose();
      // this.dismiss();
    }
    return false;
  }
  onUpdatePastFormData(aPastFormData: { formData: any; meta: any; }): void { }
  onUndoPastFormData(aPastFormData: { formData: any; meta: any; }): void { }


}
