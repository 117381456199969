<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="$event.preventDefault()" (keydown.enter)="$event.preventDefault()" class="dialog-wrap popup" style="width: 90%; margin: 0 auto;">
  <div formArrayName="array">
    <div *ngFor="let formItem of array.controls; let i = index">
      <nb-card class="small-header popup">
        <nb-card-header>
          <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('WP Product' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
        </nb-card-header>
        <nb-card-body style="overflow: hidden;">
          <div [formGroup]="formItem">
            <div class="row">
              <div class="col-sm-6">
                <ngx-form-group [formGroup]="formItem" name="Sites" label="Sites" [array]="array" [index]="i" [allowCopy]="true" [required]="false" [touchedValidate]="false">
                  <ngx-select2 formControlName="Sites" [select2Option]="select2OptionForSites" [data]="groupList">
                  </ngx-select2>
                </ngx-form-group>
              </div>
              <div class="col-sm-6">
                <ngx-form-group [formGroup]="formItem" name="ProductGroups" label="Nhóm sản phẩm" [array]="array" [index]="i" [allowCopy]="true" [required]="false" [touchedValidate]="false">
                  <nb-checkbox class="form-group-icon-control" formControlName="IsNotInGroups" nbTooltip="Ngoài trừ các nhóm này"></nb-checkbox>
                  <ngx-select2 class="form-group-input-control" formControlName="ProductGroups" [select2Option]="select2OptionForGroups" [data]="groupList"></ngx-select2>
                </ngx-form-group>
              </div>
              <div class="col-sm-6">
                <ngx-form-group [formGroup]="formItem" name="ProductCategories" label="Danh mục sản phẩm" [array]="array" [index]="i" [allowCopy]="true" [required]="false" [touchedValidate]="false">
                  <nb-checkbox class="form-group-icon-control" formControlName="IsNotInCategories" nbTooltip="Ngoại trừ các danh mục này"></nb-checkbox>
                  <ngx-select2 class="form-group-input-control" formControlName="ProductCategories" [select2Option]="select2OptionForCategories" [data]="categoryList"></ngx-select2>
                </ngx-form-group>
              </div>
              <div class="col-sm-6">
                <ngx-form-group [formGroup]="formItem" name="Products" label="Sản phẩm" [array]="array" [index]="i" [allowCopy]="true" [required]="false" [touchedValidate]="false">
                  <nb-checkbox class="form-group-icon-control" formControlName="IsNotInProducts" nbTooltip="Ngoại trừ các sản phẩm này"></nb-checkbox>
                  <ngx-select2 class="form-group-input-control" formControlName="Products" [select2Option]="select2OptionForProducts" [data]="categoryList"></ngx-select2>
                </ngx-form-group>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <button type="button" nbButton status="primary" hero size="small" (click)="loadProducts() && false">
                    <nb-icon pack="eva" icon="download-outline"></nb-icon>Nạp danh sách sản phẩm theo cấu hình
                  </button>
                </div>
              </div>
            </div>
            <label class="label">{{'Danh sách sản phẩm sẽ được đồng bộ' | translate | headtitlecase}}</label>
            <div class="ag-grid-wrap" style="height: 500px;">
              <ag-grid-angular #agGrid style="width: 100%; height: 100%;" id="myGrid" class="ag-theme-balham{{themeName ? ('-'+themeName) : ''}}"  [gridOptions]="gridOptions" [modules]="modules" [columnDefs]="columnDefs" [debug]="true" [defaultColDef]="defaultColDef" [rowSelection]="rowSelection" [rowModelType]="rowModelType" [paginationPageSize]="paginationPageSize" [cacheOverflowSize]="cacheOverflowSize" [maxConcurrentDatasourceRequests]="maxConcurrentDatasourceRequests" [infiniteInitialRowCount]="infiniteInitialRowCount" [maxBlocksInCache]="maxBlocksInCache" [cacheBlockSize]="cacheBlockSize" [getRowNodeId]="getRowNodeId" [components]="components" [rowData]="rowData" (gridReady)="onGridReady($event)" [multiSortKey]="multiSortKey" [rowDragManaged]="rowDragManaged" [getRowHeight]="getRowHeight" [rowHeight]="rowHeight" (columnResized)="onColumnResized()" (rowSelected)="onRowSelected()" [pagination]="pagination" [getRowStyle]="getRowStyle" (cellDoubleClicked)="cellDoubleClicked($event)">
              </ag-grid-angular>
            </div>
          </div>
        </nb-card-body>
        <nb-card-footer>
          <div class="buttons-row" style="text-align: right;">
            <button nbButton status="primary" [disabled]="!form.valid" style="float: right;" hero (click)="saveAndClose()">{{'Common.saveAndClose' | translate | headtitlecase}}</button>
            <button nbButton status="success" [disabled]="!form.valid" style="float: right;" hero>{{'Common.save' | translate | headtitlecase}}</button>
            <button nbButton status="danger" [disabled]="!form.valid" style="float: left;" hero (click)="sync()">
              <nb-icon pack="eva" icon="cloud-upload-outline"></nb-icon>{{'Common.sync' | translate | headtitlecase}}
            </button>
          </div>
        </nb-card-footer>
      </nb-card>
    </div>
  </div>
</form>