import { Routes } from '@angular/router';
import { MenuListComponent } from './manager-menu/menu-list/menu-list.component';
import { MenuFormComponent } from './manager-menu/menu-form/menu-form.component';


// const routes: Routes = [{
//   path: '',
//   component: MenuComponent,
//   children: [
//     // User manager
//     {
//       path: 'manager',
//       redirectTo: 'manager/list',
//       pathMatch: 'full',
//     },
//     {
//       path: 'manager/list',
//       // canActivate: [AuthGuardService],
//       component: MenuListComponent,
//       data: {
//         reuse: true,
//       },
//     },
//     {
//       path: 'manager/form',
//       // canActivate: [AuthGuardService],
//       component: MenuFormComponent,
//     },
//     {
//       path: 'manager/form/:id',
//       // canActivate: [AuthGuardService],
//       component: MenuFormComponent,
//     },
//   ],
// }];

// @NgModule({
//   imports: [RouterModule.forChild(routes)],
//   exports: [RouterModule],
// })
// export class MenuRoutingModule {
// }

export const menuRoutes: Routes = [
  // {
  //   path: 'menu/manager',
  //   redirectTo: 'menu/manager/list',
  //   pathMatch: 'full',
  // },
  {
    path: 'menu/manager/list',
    // canActivate: [AuthGuardService],
    component: MenuListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'menu/manager/form',
    // canActivate: [AuthGuardService],
    component: MenuFormComponent,
  },
  {
    path: 'menu/manager/form/:id',
    // canActivate: [AuthGuardService],
    component: MenuFormComponent,
  },
];
