<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="$event.preventDefault()" (keydown.enter)="$event.preventDefault()">
  <div formArrayName="array">
    <div *ngFor="let formItem of array.controls; let i = index">
      <nb-card class="small-header">
        <nb-card-header>
          <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('Menu.Menu.title' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
        </nb-card-header>
        <nb-card-body>
          <div [formGroup]="formItem">
            <div class="row">
              <input type="hidden" formControlName="Code_old">
              <div class="col-sm-3">
                <div class="form-group">
                  <label class="label">Menu cha <nb-icon (click)="copyFormControlValueToOthers(array, i, 'Parent')" pack="eva" icon="copy-outline"></nb-icon></label>
                  <ngx-select2 [data]="parentList" formControlName="Parent" [select2Option]="select2OptionForParent">
                  </ngx-select2>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label class="label">Mã định danh</label>
                  <input type="text" nbInput fullWidth formControlName="Code" placeholder="Mã định danh">
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label class="label">Tiêu đề</label>
                  <input type="text" nbInput fullWidth formControlName="Title" placeholder="Tiêu đề">
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group">
                  <label class="label">Group</label>
                  <nb-checkbox formControlName="Group">Group</nb-checkbox>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label class="label">Thứ tự</label>
                  <input type="text" nbInput fullWidth formControlName="No" placeholder="Thứ tự">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label class="label">Icon</label>
                  <input type="text" nbInput fullWidth formControlName="Icon" placeholder="Icon">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="label">Link</label>
                  <input type="email" nbInput fullWidth formControlName="Link" placeholder="Link">
                </div>
              </div>
            </div>






            <hr>
            <label class="label">Danh sách components</label>
            <div class="row fit-row form-detail-header">
              <div class="fit-fist-col label column">Stt</div>
              <div class="row fit-content-column">
                <div class="col-sm-5 label column">{{'Module' | translate | headtitlecase}}</div>
                <div class="col-sm-7 label column">{{'Component' | translate | headtitlecase}}</div>
              </div>
              <div class="fit-last-col column">
                <button class="fit-control-button" nbButton [outline]="true" status="success" (click)="addComponentFormGroup(i)" size="medium" hero>
                  <nb-icon pack="eva" icon="plus-circle-outline"></nb-icon>
                </button>
              </div>
            </div>
            <div formArrayName="Resources" class="form-details">

              <div class="form-detail-item" *ngFor="let component of getComponents(i).controls; let ic=index" [formGroup]="component">
                <div class="row fit-row">
                  <div class="fit-fist-col label">
                    <div class="form-group">
                      <input class="fit-sequence" nbInput fullWidth disabled="true" value="{{ ic + 1 }}">
                    </div>
                  </div>
                  <div class="row fit-content-column">
                    <div class="col-sm-12">
                      <div class="row">
                        <div class="col-sm-5">
                          <ngx-form-group [hideLabel]="true" [formGroup]="component" name="Module" label="Module" [array]="getComponents(i)" [index]="ic" [allowCopy]="true" [touchedValidate]="false">
                            <ngx-select2 [data]="moduleList" formControlName="Module" [select2Option]="select2OptionForModule" (selectChange)="onModuleChange($event, i, ic)"></ngx-select2>
                          </ngx-form-group>
                        </div>
                        <div class="col-sm-7">
                          <ngx-form-group [hideLabel]="true" [formGroup]="component" name="Component" label="Component" [array]="getComponents(i)" [index]="ic" [allowCopy]="true" [touchedValidate]="false">
                            <ngx-select2 [data]="componentList[i][ic]" formControlName="Component" [select2Option]="select2OptionForComponent"></ngx-select2>
                          </ngx-form-group>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="fit-last-col">
                    <div class="form-group" style="text-align: right;">
                      <button class="fit-control-button" nbButton status="danger" hero size="medium" (click)="removeComponentGroup(i, ic)">
                        <nb-icon pack="eva" icon="close-circle-outline"></nb-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>




            <hr>
            <label class="label">Danh sách resource</label>
            <div class="row fit-row form-detail-header">
              <div class="fit-fist-col label column">Stt</div>
              <div class="row fit-content-column">
                <div class="col-sm-3 label column">{{'Module' | translate | headtitlecase}}</div>
                <div class="col-sm-3 label column">{{'Resource' | translate | headtitlecase}}</div>
                <div class="col-sm-6 label column">{{'Permission' | translate | headtitlecase}}</div>
              </div>
              <div class="fit-last-col column">
                <button class="fit-control-button" nbButton [outline]="true" status="success" (click)="addResourceFormGroup(i)" size="medium" hero>
                  <nb-icon pack="eva" icon="plus-circle-outline"></nb-icon>
                </button>
              </div>
            </div>
            <div formArrayName="Resources" class="form-details">

              <div class="form-detail-item" *ngFor="let resource of getResources(i).controls; let ir=index" [formGroup]="resource">
                <div class="row fit-row">
                  <div class="fit-fist-col label">
                    <div class="form-group">
                      <input class="fit-sequence" nbInput fullWidth disabled="true" value="{{ ir + 1 }}">
                    </div>
                  </div>
                  <div class="row fit-content-column">
                    <div class="col-sm-12">
                      <div class="row">
                        <div class="col-sm-3">
                          <ngx-form-group [hideLabel]="true" [formGroup]="resource" name="Module" label="Module" [array]="getResources(i)" [index]="ir" [allowCopy]="true" [touchedValidate]="false">
                            <ngx-select2 [data]="moduleList" formControlName="Module" [select2Option]="select2OptionForModule" (selectChange)="onModuleChangeForResource($event, i, ir)"></ngx-select2>
                          </ngx-form-group>
                        </div>
                        <div class="col-sm-3">
                          <ngx-form-group [hideLabel]="true" [formGroup]="resource" name="Resource" label="Resource" [array]="getResources(i)" [index]="ir" [allowCopy]="true" [touchedValidate]="false">
                            <ngx-select2 [data]="getResourceChooseList(i, ir)" formControlName="Resource" [select2Option]="select2OptionForResource" (selectChange)="onResourceChangeForPermission($event, i, ir)"></ngx-select2>
                          </ngx-form-group>
                        </div>
                        <div class="col-sm-6">
                          <ngx-form-group [hideLabel]="true" [formGroup]="resource" name="Permissions" label="Permissions" [array]="getResources(i)" [index]="ir" [allowCopy]="true" [touchedValidate]="false">
                            <ngx-select2 [data]="getPermissionChooseList(i, ir)" formControlName="Permissions" [select2Option]="permissionListConfig"></ngx-select2>
                          </ngx-form-group>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="fit-last-col">
                    <div class="form-group" style="text-align: right;">
                      <button class="fit-control-button" nbButton status="danger" hero size="medium" (click)="removeResourceGroup(i, ir)">
                        <nb-icon pack="eva" icon="close-circle-outline"></nb-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <hr>
            <label class="label">Định nghĩa quyền trên menu</label>
            <div class="row fit-row form-detail-header">
              <div class="fit-fist-col label column">Stt</div>
              <div class="row fit-content-column">
                <div class="col-sm-3 label column">{{'Quyền' | translate | headtitlecase}}</div>
                <div class="col-sm-8 label column">{{'Diễn giải' | translate | headtitlecase}}</div>
                <div class="col-sm-1 label column">{{'K.Hoạt' | translate | headtitlecase}}</div>
              </div>
              <div class="fit-last-col column">
                <button class="fit-control-button" nbButton [outline]="true" status="success" (click)="addPermissionFormGroup(i)" size="medium" hero>
                  <nb-icon pack="eva" icon="plus-circle-outline"></nb-icon>
                </button>
              </div>
            </div>
            <div formArrayName="Resources" class="form-details">

              <div class="form-detail-item" *ngFor="let permission of getPermissions(i).controls; let ip=index" [formGroup]="permission">
                <div class="row fit-row">
                  <div class="fit-fist-col label">
                    <div class="form-group">
                      <input class="fit-sequence" nbInput fullWidth disabled="true" value="{{ ip + 1 }}">
                    </div>
                  </div>
                  <div class="row fit-content-column">
                    <div class="col-sm-12">
                      <div class="row">
                        <div class="col-sm-3">
                          <ngx-form-group [hideLabel]="true" [formGroup]="permission" name="Code" label="Quyền" [array]="getPermissions(i)" [index]="ip" [allowCopy]="true" [touchedValidate]="false">
                            <ngx-select2 [data]="templatePermissionList" formControlName="Code" [select2Option]="select2OptionForPermissionCode" (selectChange)="onPermimssionChange(i, ip, $event)"></ngx-select2>
                          </ngx-form-group>
                        </div>
                        <div class="col-sm-8">
                          <ngx-form-group [hideLabel]="true" [formGroup]="permission" name="Description" label="Mô tả" [array]="getPermissions(i)" [index]="ip" [allowCopy]="true" [touchedValidate]="false">
                            <input type="email" nbInput fullWidth formControlName="Description" placeholder="Diễn giải">
                          </ngx-form-group>
                        </div>
                        <div class="col-sm-1">
                          <ngx-form-group [hideLabel]="true" [formGroup]="permission" name="Status" label="Trạng thái" [array]="getPermissions(i)" [index]="ip" [allowCopy]="true" [touchedValidate]="false">
                            <nb-checkbox formControlName="Status">check</nb-checkbox>
                          </ngx-form-group>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="fit-last-col">
                    <div class="form-group" style="text-align: right;">
                      <button class="fit-control-button" nbButton status="danger" hero size="medium" (click)="removePermissionGroup(i, ip)">
                        <nb-icon pack="eva" icon="close-circle-outline"></nb-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </nb-card-body>
        <nb-card-footer>
          <div class="buttons-row" style="text-align: right;">
            <button nbButton status="warning" (click)="addFormGroup()" style="float: left;" hero>Thêm module</button>
            <button nbButton status="primary" [disabled]="!form.valid" style="float: right;" hero (click)="saveAndClose()">Lưu & Đóng</button>
            <button nbButton status="success" [disabled]="!form.valid" style="float: right;" hero (click)="save()">Lưu</button>
            <button nbButton status="info" style="float: right;" hero (click)="onGenerateMenuPermissionsBtnClick(i);">Generate Permission</button>
          </div>
        </nb-card-footer>
      </nb-card>
    </div>
  </div>
</form>