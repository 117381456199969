import { Routes } from "@angular/router";
import { AuthGuardService } from "../../services/auth-guard.service";
import { ProductTaxRateTableComponent } from "./tax-rate-table/tax-rate-table/tax-rate-table.component";
import { TaxRateUpdateNoteListComponent } from "./tax-rate-table-update-note/tax-rate-table-update-note-list/tax-rate-table-update-note-list.component";
import { ProductBrandListComponent } from "./brand/product-brand-list/product-brand-list.component";
import { ProductCategoryFormComponent } from "./category/product-category-form/product-category-form.component";
import { ProductCategoryListComponent } from "./category/product-category-list/product-category-list.component";
import { ProductKeywordListComponent } from "./keyword/product-keyword-list/product-keyword-list.component";
import { ProductGroupFormComponent } from "./product-group/product-group-form/product-group-form.component";
import { ProductGroupListComponent } from "./product-group/product-group-list/product-group-list.component";
import { ProductObjectReferenceListComponent } from "./product-object-reference/product-object-reference-list/product-object-reference-list.component";
import { ProductFormComponent } from "./product/product-form/product-form.component";
import { ProductListComponent } from "./product/product-list/product-list.component";
import { ProductPropertyListComponent } from "./property/product-property-list/product-property-list.component";
import { ProductUnitFormComponent } from "./unit/product-unit-form/product-unit-form.component";
import { ProductUnitListComponent } from "./unit/product-unit-list/product-unit-list.component";
export const adminProductRoutes: Routes = [
    {
        path: 'admin-product/product/list',
        canActivate: [AuthGuardService],
        component: ProductListComponent,
        data: {
            reuse: true,
        },
    },
    {
        path: 'admin-product/product/form',
        canActivate: [AuthGuardService],
        component: ProductFormComponent,
    },
    {
        path: 'admin-product/product/form/:id',
        canActivate: [AuthGuardService],
        component: ProductFormComponent,
    },
    {
        path: 'admin-product/category/list',
        canActivate: [AuthGuardService],
        component: ProductCategoryListComponent,
        data: {
            reuse: true,
        },
    },
    {
        path: 'admin-product/brand/list',
        canActivate: [AuthGuardService],
        component: ProductBrandListComponent,
        data: {
            reuse: true,
        },
    },
    {
        path: 'admin-product/property/list',
        canActivate: [AuthGuardService],
        component: ProductPropertyListComponent,
        data: {
            reuse: true,
        },
    },
    {
        path: 'admin-product/product-object-reference/list',
        canActivate: [AuthGuardService],
        component: ProductObjectReferenceListComponent,
        data: {
            reuse: true,
        },
    },
    {
        path: 'admin-product/product-keywords/list',
        canActivate: [AuthGuardService],
        component: ProductKeywordListComponent,
        data: {
            reuse: true,
        },
    },
    {
        path: 'admin-product/category/form',
        canActivate: [AuthGuardService],
        component: ProductCategoryFormComponent,
    },
    {
        path: 'admin-product/category/form/:id',
        canActivate: [AuthGuardService],
        component: ProductCategoryFormComponent,
    },
    {
        path: 'admin-product/unit/list',
        canActivate: [AuthGuardService],
        component: ProductUnitListComponent,
        data: {
            reuse: true,
        },
    },
    {
        path: 'admin-product/unit/form',
        canActivate: [AuthGuardService],
        component: ProductUnitFormComponent,
    },
    {
        path: 'admin-product/unit/form/:id',
        canActivate: [AuthGuardService],
        component: ProductUnitFormComponent,
    },
    // Product group

    {
        path: 'admin-product/group/list',
        canActivate: [AuthGuardService],
        component: ProductGroupListComponent,
        data: {
            reuse: true,
        },
    },
    {
        path: 'admin-product/group/form',
        canActivate: [AuthGuardService],
        component: ProductGroupFormComponent,
    },
    {
        path: 'admin-product/group/form/:id',
        canActivate: [AuthGuardService],
        component: ProductGroupFormComponent,
    },
    {
        path: 'admin-product/tax-rate/table',
        canActivate: [AuthGuardService],
        component: ProductTaxRateTableComponent,
        data: {
            reuse: true,
        },
    },
    {
        path: 'admin-product/tax-rate/update-note/list',
        canActivate: [AuthGuardService],
        component: TaxRateUpdateNoteListComponent,
        data: {
            reuse: true,
        },
    },
];