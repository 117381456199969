import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NbToastrService, NbDialogService, NbDialogRef } from '@nebular/theme';
import { CurrencyMaskConfig } from 'ng2-currency-mask';
import { environment } from '../../../../../environments/environment.prod';
import { ActionControlListOption } from '../../../../lib/custom-element/action-control-list/action-control.interface';
import { DataManagerFormComponent, MyUploadAdapter } from '../../../../lib/data-manager/data-manager-form.component';
import { ContactModel } from '../../../../models/contact.model';
import { ApiService } from '../../../../services/api.service';
import { CommonService } from '../../../../services/common.service';
import { RootServices } from '../../../../services/root.services';
import { AccountingService } from '../../../accounting/accounting.service';
import { AdminProductService } from '../../../admin-product/admin-product.service';
import { MobileAppService } from '../../../mobile-app/mobile-app.service';
import { ContractPrintComponent } from '../contract-print/contract-print.component';
import { Model } from '../../../../models/model';
import * as ClassicEditorBuild from '../../../../../vendor/ckeditor/ckeditor5-custom-build/build/ckeditor.js';
import { ReferenceChoosingDialogComponent } from '../../../dialog/reference-choosing-dialog/reference-choosing-dialog.component';
import { Select2Option } from '../../../../lib/custom-element/select2/select2.component';

function MyCustomUploadAdapterPlugin(editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
    // Configure the URL to the upload script in your back-end here!
    const options = editor.config.get('simpleUpload');
    return new MyUploadAdapter(loader, options);
  };
}

@Component({
  selector: 'ngx-contract-form',
  templateUrl: './contract-form.component.html',
  styleUrls: ['./contract-form.component.scss']
})
export class ContractFormComponent extends DataManagerFormComponent<Model> implements OnInit {

  componentName: string = 'ContractFormComponent';
  idKey = 'Code';
  baseFormUrl = '/contract/contract/form';
  apiPath = '/contract/contracts';

  env = environment;

  locale = this.cms.getCurrentLoaleDataset();
  curencyFormat: CurrencyMaskConfig = this.cms.getCurrencyMaskConfig();
  numberFormat: CurrencyMaskConfig = this.cms.getNumberMaskConfig();

  // locale = this.commo nService.getCurrentLoaleDataset();
  priceCurencyFormat: CurrencyMaskConfig = { ...this.cms.getCurrencyMaskConfig(), precision: 0 };
  toMoneyCurencyFormat: CurrencyMaskConfig = { ...this.cms.getCurrencyMaskConfig(), precision: 0 };
  quantityFormat: CurrencyMaskConfig = { ...this.cms.getNumberMaskConfig(), precision: 2 };

  towDigitsInputMask = this.cms.createFloatNumberMaskConfig({
    digitsOptional: false,
    digits: 2
  });

  select2OptionForAObject = {
    ...this.cms.makeSelect2AjaxOption('/contract/business-infos', { includeIdText: true, eq_State: 'APPROVED' }, {}),
  };

  select2OptionForBobjectType: Select2Option = {
    placeholder: 'Chọn...',
    // allowClear: true,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    withThumbnail: false,
    keyMap: {
      id: 'id',
      text: 'text',
    },
    data: [
      {id: 'ORGANIZATION', text: 'Tổ chức'},
      {id: 'PERSONAL', text: 'Cá nhân'},
    ]
  };
  
  select2OptionForType: Select2Option = {
    placeholder: 'Chọn...',
    // allowClear: true,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    withThumbnail: false,
    keyMap: {
      id: 'id',
      text: 'text',
    },
    data: [
      {id: 'PRINCIPLE', text: 'Hợp đồng nguyên tắc'},
      {id: 'SALECONTRACT', text: 'Hợp đồng mua bán'},
    ]
  };


  public Editor = ClassicEditorBuild;
  public ckEditorConfig = {
    height: '200px',
    // plugins: [ImageResize],
    extraPlugins: [MyCustomUploadAdapterPlugin],
    simpleUpload: {
      uploadUrl: () => {
        // return this.apiService.getPromise<FileStoreModel[]>('/file/file-stores', { filter_Type: 'REMOTE', sort_Weight: 'asc', requestUploadToken: true, weight: 4194304, limit: 1 }).then(fileStores => {
        return this.cms.getAvailableFileStores().then(fileStores => fileStores[0]).then(fileStore => {
          return this.apiService.buildApiUrl(fileStore.Path + '/v1/file/files', { token: fileStore['UploadToken'] });
        });
      },
    },
  };

  constructor(
    public rsv: RootServices,
    public activeRoute: ActivatedRoute,
    public router: Router,
    public formBuilder: FormBuilder,
    public apiService: ApiService,
    public toastrService: NbToastrService,
    public dialogService: NbDialogService,
    public cms: CommonService,
    public adminProductService: AdminProductService,
    public ref: NbDialogRef<ContractFormComponent>,
    public mobileAppService: MobileAppService,
    public accountingService: AccountingService,
  ) {
    super(rsv, activeRoute, router, formBuilder, apiService, toastrService, dialogService, cms);

    /** Append print button to head card */
    this.actionButtonList.splice(this.actionButtonList.length - 1, 0, {
      name: 'print',
      status: 'primary',
      label: this.cms.textTransform(this.cms.translate.instant('Common.print'), 'head-title'),
      icon: 'printer',
      title: this.cms.textTransform(this.cms.translate.instant('Common.print'), 'head-title'),
      size: 'medium',
      disabled: () => this.isProcessing,
      hidden: () => false,
      click: (event: any, option: ActionControlListOption) => {
        this.preview(option.form);
      },
    });
  }

  select2OptionForTemplate = {
    ...this.cms.makeSelect2AjaxOption('/contract/templates', () => {
      return {
        includeIdText: true,
      };
    }, {
      placeholder: 'Chọn mẫu...',
      limit: 10,
      prepareReaultItem: (item) => {
        return item;
      }
    }),
    placeholder: 'Chọn mẫu...',
  };

  // getRequestId(callback: (id?: string[]) => void) {
  //   callback(this.inputId);
  // }

  getRequestId(callback: (id?: string[]) => void) {
    // callback(this.inputId);
    return super.getRequestId(callback);
  }

  ngOnInit() {
    this.restrict();
    super.ngOnInit();
  }

  async init(): Promise<boolean> {

    return super.init().then(async status => {
      if (this.isDuplicate) {
        // Clear id
        this.id = [];
        this.array.controls.forEach((formItem, index) => {
          formItem.get('Code').setValue('');
          // formItem.get('Title').setValue('Copy of: ' + formItem.get('Title').value);

        });
      }
      return status;
    });

  }

  /** Execute api get */
  executeGet(params: any, success: (resources: Model[]) => void, error?: (e: HttpErrorResponse) => void) {
    params['includeRelativeVouchers'] = true;
    super.executeGet(params, async rs => {
      for(const i in rs) {
        const itemFormData = rs[i];
        const aObject = await this.apiService.getPromise('/contract/business-infos/' + this.encodeId(this.cms.getObjectId(itemFormData.AObject)), { includeIdText: true }).then(rs => rs[0]);
        if (aObject) {
          itemFormData.AObject = aObject;
        }
      }
      success && success(rs);
    }, error);
  }

  async formLoad(formData: Model[], formItemLoadCallback?: (index: number, newForm: FormGroup, formData: Model) => void) {
    // for(const i in formData) {
    //   const itemFormData = formData[i];
    //   if (itemFormData && itemFormData.AObject) {
    //     const aObject = await this.apiService.getPromise('/contract/business-infos/' + this.encodeId(this.cms.getObjectId(itemFormData.AObject)), { includeIdText: true }).then(rs => rs[0]);
    //     if (aObject) {
    //       itemFormData.AObject = aObject;
    //     }
    //   }
    // }
    return super.formLoad(formData, async (index, newForm, itemFormData) => {

      

      // Direct callback
      if (formItemLoadCallback) {
        formItemLoadCallback(index, newForm, itemFormData);
      }
    });

  }

  makeNewFormGroup(data?: Model): FormGroup {
    const newForm = this.formBuilder.group({
      Code: { value: null, disabled: true },
      Type: [null],
      Template: [null],
      Title: [null],
      Description: [null],
      ValidFromDate: [null],
      ValidToDate: [null],
      AObject: [null],
      AObjectName: { value: null, disabled: true },
      AObjectShortName: [null],
      AObjectBusinessCode: [null],
      AObjectTaxCode: [null],
      AObjectAddress: [null],
      AObjectPhone: [null],
      AObjectEmail: [null],
      ABankName: [null],
      ABankAccount: [null],
      // AObjectIdentityCardNumber: [null],
      // AObjectIdentityCardImage: [null],
      ADelegator: [null],
      ADelegatorName: [null],
      ADelegatorPosition: [null],
      ASigningDelegator: [null],
      ASigningDelegatorName: [null],
      ASigningDelegatorPosition: [null],
      AIdentityPhoneNumber: [null],
      ABusinessLicenseImage: [null],
      ADelegateNoteImage: [null],

      BObject: [],
      BObjectType: ['ORGANIZATION'],
      BObjectName: [],
      BObjectShortName: [],
      BObjectBusinessCode: [],
      BObjectIdentityPhoneNumber: [],
      BObjectEmail: [],
      BObjectAddress: [],
      BObjectBirthday: [],
      BObjectPlaceOfOrigin: [],
      BObjectIdentityCardNumber: [],
      BObjectIdentityCardImage: [],
      BBusinessLicenseImage: [],
      BDelegateNoteImage: [],
      BDelegator: [],
      BDelegatorName: [],
      BDelegatorPosition: [],

      Content: [],
      RelativeVouchers: [],
    });
    this.disabledControls.push(newForm.get('Code'));
    if (data) {
      // data['Code_old'] = data['Code'];
      newForm.patchValue(data);
    }
    return newForm;
  }
  onAddFormGroup(index: number, newForm: FormGroup, formData?: Model): void {
    super.onAddFormGroup(index, newForm, formData);
  }
  onRemoveFormGroup(index: number): void {

  }

  goback(): false {
    // super.goback();
    if (this.mode === 'page') {
      this.router.navigate(['/collaborator/opportunity/list']);
    } else {
      this.ref.close();
    }
    return false;
  }

  onUpdatePastFormData(aPastFormData: { formData: any; meta: any; }): void { }
  onUndoPastFormData(aPastFormData: { formData: any; meta: any; }): void { }


  async preview(formItem: FormGroup) {
    const data: Model = formItem.value;
    this.cms.openDialog(ContractPrintComponent, {
      context: {
        showLoadinng: true,
        title: 'Xem trước',
        data: [data],
        idKey: ['Code'],
        onSaveAndClose: (priceReport: Model) => {
          this.saveAndClose();
        },
        onSaveAndPrint: (priceReport: Model) => {
          this.save();
        },
      },
    });
    return false;
  }

  getRawFormData() {
    return super.getRawFormData();
  }

  onAObjectChange(formGroup: FormGroup, selectedData: ContactModel, formIndex?: number) {
    // console.info(item);

    if (!this.isProcessing) {
      if (selectedData && !selectedData['doNotAutoFill']) {

        if (selectedData.id) {
          formGroup.get('AObjectName').setValue(selectedData.Name);
          formGroup.get('AObjectShortName').setValue(selectedData.ShortName);
          formGroup.get('AObjectBusinessCode').setValue(selectedData.TaxCode);
          formGroup.get('AObjectPhone').setValue(selectedData.Phone);
          formGroup.get('AObjectEmail').setValue(selectedData.Email);
          formGroup.get('AObjectAddress').setValue(selectedData.Address);
          formGroup.get('ADelegatorName').setValue(selectedData.Delegator);
          formGroup.get('ADelegatorPosition').setValue(selectedData.DelegatorPosition);
          formGroup.get('ABankName').setValue(selectedData.BankName);
          formGroup.get('ABankAccount').setValue(selectedData.BankAccount);
        }
      }
    }
  }
  onADelegatorChange(formGroup: FormGroup, selectedData: ContactModel, formIndex?: number) {
    // console.info(item);

    if (!this.isProcessing) {
      if (selectedData && !selectedData['doNotAutoFill']) {

        if (selectedData.id) {
          formGroup.get('ADelegatorName').setValue(selectedData.Name);
        }
      }
    }
  }
  onASigningDelegatorChange(formGroup: FormGroup, selectedData: ContactModel, formIndex?: number) {
    // console.info(item);

    if (!this.isProcessing) {
      if (selectedData && !selectedData['doNotAutoFill']) {

        if (selectedData.id) {
          formGroup.get('ASigningDelegatorName').setValue(selectedData.Name);
        }
      }
    }
  }
  onBObjectChange(formGroup: FormGroup, selectedData: ContactModel, formIndex?: number) {
    // console.info(item);

    if (!this.isProcessing) {
      if (selectedData && !selectedData['doNotAutoFill']) {

        if (selectedData.id) {
          formGroup.get('BObjectName').setValue(selectedData.Name);
          formGroup.get('BObjectIdentityPhoneNumber').setValue(selectedData.Phone);
          formGroup.get('BObjectEmail').setValue(selectedData.Email);
          formGroup.get('BObjectAddress').setValue(selectedData.Address);
        }
      }
    }
  }

  onTemplateChange(formGroup: FormGroup, selectedData: Model, formIndex?: number) {
    // console.info(item);

    if (!this.isProcessing) {
      if (selectedData && !selectedData['doNotAutoFill']) {

        if (selectedData.id) {
          this.apiService.getPromise<Model[]>('/contract/templates/' + selectedData.id).then(rs => rs[0]).then(template => {
            if (!formGroup.get('Content').value?.trim()) {
              formGroup.get('Content').setValue(template.Content);
            } else {
              this.cms.showDialog('Chọn mẫu hợp đồng', 'Bạn có muốn ghi đè nội dung hợp đồng hiện tại không ?', [
                {
                  label: 'Trở về',
                  status: 'basic',
                  outline: true,
                  action: () => {

                  },
                },
                {
                  label: 'Ghi đè',
                  status: 'danger',
                  action: () => {
                    formGroup.get('Content').setValue(template.Content);
                  },
                },
              ]);
            }
          });
        }
      }
    }
  }

  openRelativeVoucherChoosedDialog(formGroup: FormGroup) {
    this.cms.openDialog(ReferenceChoosingDialogComponent, {
      context: {
        components: {
          'CLBRTOPPORTUNITY': { title: 'Cơ hội CTV' },
          // 'GOODSRECEIPT': { title: 'Phiếu nhập kho' },
        },
        // inputMode: 'dialog',
        onDialogChoose: async (chooseItems: any[], type?: string) => {
          console.log(chooseItems);
          const relationVoucher = formGroup.get('RelativeVouchers');
          const relationVoucherValue: any[] = (relationVoucher.value || []);
          const insertList = [];
          this.onProcessing();
          if (type === 'CLBRTOPPORTUNITY') {
            // const details = this.getDetails(formGroup);
            for (let i = 0; i < chooseItems.length; i++) {
              const index = relationVoucherValue.findIndex(f => f?.id === chooseItems[i]?.Code);
              if (index < 0) {
                // get purchase order
                const voucher = await this.apiService.getPromise<Model[]>('/collaborator/opportunities/' + chooseItems[i].Code, { includeContact: true, includeDetails: true, includeRelativeVouchers: true, includeIdText: true }).then(rs => rs[0]);

                // Check purchase order state
                if (['APPROVED', 'NEW', 'FREEZE', 'COOL', 'WARM', 'HOT', 'CONVERTED'].indexOf(this.cms.getObjectId(voucher.State)) < 0) {
                  this.cms.showToast(this.cms.translateText('Trạng thái {{state}} không được hỗ trợ', { state: this.cms.getObjectText(voucher.State) }), this.cms.translateText('Common.warning'), { status: 'warning' });
                  continue;
                }

                // Check PO require goods receipt
                if (voucher) {
                  // if (Array.isArray(voucher.Details) && voucher.Details.some(f => f.Business && Array.isArray(f.Business) && f.Business.some(s => this.cms.getObjectId(s) == 'PURCHASEWAREHOUSE'))) {
                  //   // Check goods receipt notes
                  //   // const relativeGoodsReceiptVouchers = voucher.RelativeVouchers.filter(f => f.type == 'GOODSRECEIPT');
                  //   // if (relativeGoodsReceiptVouchers.length == 0) {
                  //   //   this.cms.showDialog('Thêm chứng từ liên quan', 'Không thể tạo phiếu mua hàng từ đơn đặt hàng chưa được nhập kho !', []);
                  //   //   continue;
                  //   // }
                  //   // const goodsReceiptNotes = await this.apiService.getPromise<Model[]>('/warehouse/goods-receipt-notes', { id: relativeGoodsReceiptVouchers.map(m => this.cms.getObjectId(m)), includeIdText: true });
                  //   // for (const goodsReceiptNote of goodsReceiptNotes) {
                  //   //   if (goodsReceiptNote.State != 'APPROVED') {
                  //   //     this.cms.showDialog('Thêm chứng từ liên quan', 'Phiếu nhập kho liên quan chưa được duyệt !', []);
                  //   //     continue;
                  //   //   }
                  //   //   insertList.push({ id: goodsReceiptNote.Code, text: goodsReceiptNote.Title, type: 'GOODSRECEIPT' });
                  //   // }
                  // }

                  // if (this.cms.getObjectId(formGroup.get('Object').value)) {
                  //   if (this.cms.getObjectId(voucher.Object, 'Code') != this.cms.getObjectId(formGroup.get('Object').value)) {
                  //     this.cms.showToast(this.cms.translateText('Nhà cung cấp trong phiếu đặt mua hàng không giống với phiếu mua hàng'), this.cms.translateText('Common.warning'), { status: 'warning' });
                  //     continue;
                  //   }
                  // } else {
                  //   delete voucher.Id;
                  //   formGroup.patchValue({ ...voucher, DateOfPurchase: voucher.DateOfOrder, Code: null, Id: null, Details: [] });
                  //   // details.clear();
                  // }
                  insertList.push({ id: chooseItems[i].Code, text: chooseItems[i].Title, type: 'CLBRTOPPORTUNITY' });

                  // Insert order details into voucher details
                  // if (voucher?.Details) {
                  //   // details.push(this.makeNewDetailFormGroup(formGroup, { Type: 'CATEGORY', Description: 'Phiếu đặt mua hàng: ' + voucher.Code + ' - ' + voucher.Title }));
                  //   // for (const voucherDetail of voucher.Details) {
                  //   //   if (voucherDetail.Type == 'CATEGORY') {
                  //   //     const newDetailFormGroup = this.makeNewDetailFormGroup(formGroup, { ...voucherDetail, Id: null, No: null, Voucher: null, Business: null, RelateDetail: `PURCHASEORDER/${voucher.Code}/${voucherDetail.SystemUuid}` } as any);
                  //   //     details.push(newDetailFormGroup);
                  //   //   } else {
                  //   //     const newDetailFormGroup = this.makeNewDetailFormGroup(formGroup, { ...voucherDetail, Id: null, No: null, Voucher: null, RelateDetail: `PURCHASEORDER/${voucher.Code}/${voucherDetail.SystemUuid}` } as any);
                  //   //     details.push(newDetailFormGroup);
                  //   //     await new Promise(resolve => setTimeout(() => resolve(true), 300));
                  //   //     this.toMoney(formGroup, newDetailFormGroup);
                  //   //   }
                  //   // }
                  // }
                }
              }
            }
            relationVoucher.setValue([...relationVoucherValue, ...insertList.map(m => ({ id: m?.id, text: m.text, type: m.type, typeMap: this.cms.voucherTypeMap[type] }))]);
            // this.setNoForArray(details.controls as FormGroup[], (detail: FormGroup) => detail.get('Type').value === 'PRODUCT');
          }
          setTimeout(() => {
            this.onProcessed();
          }, 1000);
        },
      }
    })
    return false;
  }

  openRelativeVoucher(relativeVocher: any) {
    if (relativeVocher) {
      this.cms.previewVoucher(this.cms.getObjectId(relativeVocher.type), relativeVocher)
    };
    return false;
  }

}
