import { ColDef } from "@ag-grid-community/core";
import { CommonService } from "../../../../services/common.service";
import { AgDateCellRenderer } from "../cell/date.component";

export const agMakeDatetimeColDef = (
  cms?: CommonService,
): ColDef => {
  return {
    cellRenderer: AgDateCellRenderer,
    filter: 'agDateColumnFilter',
    filterParams: {
      inRangeFloatingFilterDateFormat: 'DD/MM/YY',
    },
  }
}