import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogRef, NbDialogService, NbThemeService, NbToastrService } from '@nebular/theme';
import { filter, takeUntil } from 'rxjs/operators';
import { AccountModel } from '../../../../models/accounting.model';
import { ApiService } from '../../../../services/api.service';
import { CommonService } from '../../../../services/common.service';
import { AccAccountFormComponent } from '../../acc-account/acc-account-form/acc-account-form.component';
import { AccountingService } from '../../accounting.service';
import { AccountingDetailByObjectReportAgComponent } from '../accounting-detail-by-object-report-ag/accounting-detail-by-object-report-ag.component';
import { AccountingAccountDetailsReportPrintComponent } from './../print/accounting-account-details-report-print/accounting-account-details-report-print.component';
import { RootServices } from '../../../../services/root.services';
import { AgGridDataManagerListComponent } from '../../../../lib/data-manager/ag-grid-data-manger-list.component';
import { Model } from '../../../../models/model';
import { PurchaseOrderVoucherFormComponent } from '../../../purchase/order/purchase-order-voucher-form/purchase-order-voucher-form.component';
import { agMakeCommandColDef } from '../../../../lib/custom-element/ag-list/column-define/command.define';
import { ColDef, IGetRowsParams } from '@ag-grid-community/core';
import { agMakeCurrencyColDef } from '../../../../lib/custom-element/ag-list/column-define/currency.define';
import { AgSelect2Filter } from '../../../../lib/custom-element/ag-list/filter/select2.component.filter';
import { AgTextCellRenderer } from '../../../../lib/custom-element/ag-list/cell/text.component';
import { agMakeSelectionColDef } from '../../../../lib/custom-element/ag-list/column-define/selection.define';
import { AppModule } from '../../../../app.module';
import { AccountingReceivablesFromCustomersDetailsReportPrintComponent } from '../print/accounting-receivables-from-customers-details-report-print/accounting-receivables-from-customers-details-report-print.component';
import { AccountingReceivablesFromCustomersVoucherssReportPrintComponent } from '../print/accounting-receivables-from-customers-vouchers-report-print/accounting-receivables-from-customers-vouchers-report-print.component';
import { AccountingLiabilitiesDetailsReportPrintComponent } from '../print/accounting-liabilities-details-report-print/accounting-liabilities-details-report-print.component';
import { AccountingLiabilitiesReportPrintComponent } from '../print/accounting-liabilities-report-print/accounting-liabilities-report-print.component';

@Component({
  selector: 'ngx-summary-report',
  templateUrl: './accounting-summary-report.component.html',
  styleUrls: ['./accounting-summary-report.component.scss']
})
export class AccountingSummaryReportComponent extends AgGridDataManagerListComponent<Model, PurchaseOrderVoucherFormComponent> implements OnInit {


  componentName: string = 'AccountingSummaryReportComponent';
  formPath = '/accounting/account/form';
  apiPath = '/accounting/reports';
  idKey = ['Account'];
  formDialog = AccAccountFormComponent;

  reuseDialog = true;

  // Smart table
  static filterConfig: any;
  static sortConf: any;
  static pagingConf = { page: 1, perPage: 40 };

  totalBalance: { Debit: number, Credit: number } = null;
  tabs: any[];

  constructor(
    public rsv: RootServices,
    public apiService: ApiService,
    public router: Router,
    public cms: CommonService,
    public dialogService: NbDialogService,
    public toastService: NbToastrService,
    public themeService: NbThemeService,
    public _http: HttpClient,
    public ref: NbDialogRef<AccountingSummaryReportComponent>,
    public accountingService: AccountingService,
  ) {
    super(rsv, apiService, router, cms, dialogService, toastService, themeService, ref);

    this.defaultColDef = {
      ...this.defaultColDef,
      cellClass: 'ag-cell-items-center',
    }

    this.pagination = false;
    // this.maxBlocksInCache = 5;
    this.paginationPageSize = 100;
    this.cacheBlockSize = 100;
  }

  async init() {
    // await this.loadCache();
    await this.cms.waitForReady();
    this.tabs = [
      {
        title: 'Liabilities',
        route: '/accounting/report/liabilities',
        icon: 'home',
        // responsive: true, // hide title before `route-tabs-icon-only-max-width` value
      },
      {
        title: 'Receivables',
        route: '/accounting/report/receivables',
      },
      {
        title: 'Users',
        icon: 'person',
        route: './tab1',
      },
      {
        title: 'Orders',
        icon: 'paper-plane-outline',
        responsive: true,
        route: ['./tab2'],
      },
      {
        title: 'Transaction',
        icon: 'flash-outline',
        responsive: true,
        disabled: true,
      },
    ];
    return super.init().then(async rs => {
      this.actionButtonList = this.actionButtonList.filter(f => ['delete', 'edit', 'add', 'choose'].indexOf(f.name) < 0);
      this.actionButtonList.find(f => f.name === 'refresh').label = this.cms.translateText('Common.refresh');

      const summaryReportBtn = this.actionButtonList.find(f => f.name == 'preview');
      summaryReportBtn.label = summaryReportBtn.title = 'In báo cáo sổ chi tiết tài khoản';
      summaryReportBtn.icon = 'printer';
      summaryReportBtn.status = 'info';
      summaryReportBtn.disabled = () => this.selectedIds.length <= 0;
      summaryReportBtn.click = () => {
        this.cms.openDialog(AccountingAccountDetailsReportPrintComponent, {
          context: {
            showLoadinng: true,
            // title: 'Xem trước',
            accounts: this.selectedIds,
            mode: 'print',
            id: ['all']
          },
        });
      };

      // const detailsReportBtn = {...summaryReportBtn};
      // detailsReportBtn.status = 'primary';
      // detailsReportBtn.name = 'detailReport';
      // detailsReportBtn.label = detailsReportBtn.title = 'In báo cáo chi tiết';
      // detailsReportBtn.disabled = () => this.selectedIds.length <= 0;
      // detailsReportBtn.click = () => {
      //   this.cms.openDialog(AccountingAccountDetailsReportPrintComponent, {
      //     context: {
      //       showLoadinng: true,
      //       // title: 'Xem trước',
      //       mode: 'print',
      //       id: ['all'],
      //       objects: this.selectedIds,
      //     },
      //   });
      // };
      // this.actionButtonList.unshift(detailsReportBtn);

      // Auto refresh list on reportToDate changed
      this.accountingService?.reportToDate$.pipe(takeUntil(this.destroy$), filter(f => f !== null)).subscribe(toDate => {
        console.log(toDate);
        this.refresh();
      });
      this.accountingService?.reportFromDate$.pipe(takeUntil(this.destroy$), filter(f => f !== null)).subscribe(fromDate => {
        console.log(fromDate);
        this.refresh();
      });

      // const processingMap = AppModule.processMaps['purchaseOrder'];
      await this.cms.waitForLanguageLoaded();
      this.columnDefs = this.configSetting([
        {
          ...agMakeSelectionColDef(this.cms),
          headerName: 'ID',
          field: 'Id',
          width: 100,
          valueGetter: 'node.data.Account',
          // sortingOrder: ['desc', 'asc'],
          // initialSort: 'desc',
        },
        {
          headerName: this.cms.translateText('Accounting.account'),
          field: 'Accounts',
          width: 200,
          pinned: 'left',
          cellRenderer: (params) => {
            if (params && params.data) {
              return params.data.Account;
            }
          },
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              placeholder: 'Chọn...',
              allowClear: true,
              width: '100%',
              dropdownAutoWidth: true,
              minimumInputLength: 0,
              // withThumbnail: true,
              keyMap: {
                id: 'id',
                text: 'text',
              },
              ajax: {
                data: function (params) {
                  return {
                    ...params,
                    offset: params['offset'] || 0,
                    limit: params['limit'] || 10
                  };
                },
                transport: (settings: JQueryAjaxSettings, success?: (data: any) => null, failure?: () => null) => {
                  const params = settings.data;
                  const offset = settings.data['offset'];
                  const limit = settings.data['limit'];
                  const results = !params['term'] ? this.accountingService.accountList$?.value : this.accountingService.accountList$?.value.filter(f => (new RegExp('^' + this.cms.convertUnicodeToNormal(params['term']), 'ig')).test(this.cms.convertUnicodeToNormal(f.Code)) || (new RegExp(this.cms.convertUnicodeToNormal(params['term']).toLowerCase().replace(/\s+/g, '.*'), 'ig')).test(this.cms.convertUnicodeToNormal(f.Name)));
                  success({ data: results.slice(offset, offset + limit).map(m => { m.label = m.Code; return m; }), total: results.length });
                  return null;
                },
                delay: 300,
                processResults: (rs: { data: any[], total: number }, params: any) => {
                  const data = rs.data;
                  const total = rs.total;
                  params.limit = params.limit || 10;
                  params.offset = params.offset || 0;
                  params.offset = params.offset += params.limit;
                  return {
                    results: data.map(item => {
                      item.id = item.Code;
                      item.text = `${item.Code} - ${item.Name}`;
                      // item.thumbnail = item?.FeaturePicture?.Thumbnail;
                      return item;
                    }),
                    pagination: {
                      more: params.offset < total
                    }
                  };
                },
              },
              multiple: true,
              logic: 'OR',
              // allowClear: true,
            }
          },
        },
        {
          headerName: this.cms.translateText('Common.description'),
          field: 'AccountName',
          width: 512,
          // pinned: 'left',
          cellRenderer: AgTextCellRenderer
        },
        {
          ...agMakeCurrencyColDef(this.cms),
          headerName: 'Đầu kỳ',
          field: 'HeadAmount',
          pinned: 'right',
          width: 200,
        },
        {
          ...agMakeCurrencyColDef(this.cms),
          headerName: 'PS.Nợ',
          field: 'GenerateDebit',
          pinned: 'right',
          width: 200,
        },
        {
          ...agMakeCurrencyColDef(this.cms),
          headerName: 'PS.Có',
          field: 'GenerateCredit',
          pinned: 'right',
          width: 200,
        },
        {
          ...agMakeCurrencyColDef(this.cms),
          headerName: 'Cuối kỳ',
          field: 'TailAmount',
          pinned: 'right',
          width: 200,
        },
        {
          ...agMakeCommandColDef(this, this.cms, false, false, false, [
            {
              name: 'REPORT',
              label: 'Chi tiết',
              title: 'Báo cáo chi tiết theo tài khoản',
              status: 'primary',
              outline: true,
              action: async (params: any, buttonConfig?: any) => {
                this.openInstantDetailReport(params.data);
                return true;
              }
            }
          ]),
          headerName: 'Lệnh',
          maxWidth: 100,
          resizable: true,
        },
      ] as ColDef[]);

      return rs;
    });
  }

  editing = {};
  rows = [];

  // loadListSetting(): SmartTableSetting {
  //   return this.configSetting({
  //     actions: false,
  //     columns: {
  //       Accounts: {
  //         title: this.cms.translateText('Accounting.account'),
  //         type: 'string',
  //         width: '10%',
  //         valuePrepareFunction: (cell: any, row: AccountModel) => {
  //           return row.Account;
  //         },
  //         filter: {
  //           type: 'custom',
  //           component: SmartTableSelect2FilterComponent,
  //           config: {
  //             delay: 0,
  //             condition: 'eq',
  //             select2Option: {
  //               logic: 'OR',
  //               placeholder: 'Chọn tài khoản...',
  //               allowClear: true,
  //               width: '100%',
  //               dropdownAutoWidth: true,
  //               minimumInputLength: 0,
  //               keyMap: {
  //                 id: 'id',
  //                 text: 'text',
  //               },
  //               multiple: true,
  //               ajax: {
  //                 transport: (settings: JQueryAjaxSettings, success?: (data: any) => null, failure?: () => null) => {
  //                   console.log(settings);
  //                   const params = settings.data;
  //                   this.apiService.getPromise('/accounting/accounts', { 'search': params['term'], includeIdText: true }).then(rs => {
  //                     success(rs);
  //                   }).catch(err => {
  //                     console.error(err);
  //                     failure();
  //                   });
  //                 },
  //                 delay: 300,
  //                 processResults: (data: any, params: any) => {
  //                   // console.info(data, params);
  //                   return {
  //                     results: data.map(item => {
  //                       item.text = `${item.id} - ${item.text}`;
  //                       return item;
  //                     }),
  //                   };
  //                 },
  //               },
  //             },
  //           },
  //         },
  //       },
  //       AccountName: {
  //         title: this.cms.translateText('Common.description'),
  //         type: 'string',
  //         width: '40%',
  //       },
  //       // HeadDebit: {
  //       //   title: '[' + this.cms.translateText('Accounting.headDebit'),
  //       //   type: 'acc-currency',
  //       //   width: '10%',
  //       // },
  //       // HeadCredit: {
  //       //   title: this.cms.translateText('Accounting.headCredit') + ']',
  //       //   type: 'acc-currency',
  //       //   width: '10%',
  //       // },
  //       HeadAmount: {
  //         title: '[' + this.cms.translateText('Accounting.headAmount') + ']',
  //         type: 'acc-currency',
  //         width: '10%',
  //       },
  //       GenerateDebit: {
  //         title: '[' + this.cms.translateText('Accounting.debitGenerate'),
  //         type: 'acc-currency',
  //         width: '10%',
  //       },
  //       GenerateCredit: {
  //         title: this.cms.translateText('Accounting.creditGenerate') + ']',
  //         type: 'acc-currency',
  //         width: '10%',
  //       },
  //       // TailDebit: {
  //       //   title: '[' + this.cms.translateText('Accounting.tailDebit'),
  //       //   type: 'acc-currency',
  //       //   width: '10%',
  //       // },
  //       // TailCredit: {
  //       //   title: this.cms.translateText('Accounting.tailCredit') + ']',
  //       //   type: 'acc-currency',
  //       //   width: '10%',
  //       // },
  //       TailAmount: {
  //         title: '[' + this.cms.translateText('Accounting.tailAmount') + ']',
  //         type: 'acc-currency',
  //         width: '10%',
  //       },
  //       Preview: {
  //         title: this.cms.translateText('Common.detail'),
  //         type: 'custom',
  //         width: '10%',
  //         class: 'align-right',
  //         renderComponent: SmartTableButtonComponent,
  //         onComponentInitFunction: (instance: SmartTableButtonComponent) => {
  //           instance.iconPack = 'eva';
  //           instance.icon = 'external-link-outline';
  //           instance.display = true;
  //           instance.status = 'primary';
  //           instance.style = 'text-align: right';
  //           instance.class = 'align-right';
  //           instance.title = this.cms.translateText('Common.preview');
  //           instance.label = this.cms.translateText('Common.detail');
  //           instance.valueChange.subscribe(value => {
  //             // instance.icon = value ? 'unlock' : 'lock';
  //             // instance.status = value === 'REQUEST' ? 'warning' : 'success';
  //             // instance.disabled = value !== 'REQUEST';
  //           });
  //           instance.click.pipe(takeUntil(this.destroy$)).subscribe((rowData: any) => {
  //             this.openInstantDetailReport(rowData);
  //           });
  //         },
  //       }
  //     },
  //   });
  // }

  ngOnInit() {
    this.restrict();
    super.ngOnInit();
  }

  // initDataSource() {
  //   const source = super.initDataSource();

  //   // Set DataSource: prepareParams
  //   source.prepareParams = (params: any) => {
  //     params['includeParent'] = true;
  //     params['includeAmount'] = true;
  //     return params;
  //   };

  //   return source;
  // }

  /** Api get funciton */
  // executeGet(params: any, success: (resources: AccountModel[]) => void, error?: (e: HttpErrorResponse) => void, complete?: (resp: AccountModel[] | HttpErrorResponse) => void) {
  //   // params['includeParent'] = true;
  //   params['reportSummary'] = true;
  //   params['Accounts'] = '111,112,128,131,138,141,136,211,331,338,511,512,515,632,635,641,642,711,4222,811,156,1331,3331,4212,4111,4112,4118,3341,3411,3348,3349,3350,6411,4211,3412,2288';

  //   const choosedFromDate = (this.accountingService.reportFromDate$.value as Date) || new Date();
  //   const fromDate = new Date(choosedFromDate.getFullYear(), choosedFromDate.getMonth(), choosedFromDate.getDate(), 0, 0, 0, 999);

  //   const choosedToDate = (this.accountingService.reportToDate$.value as Date) || new Date();
  //   const toDate = new Date(choosedToDate.getFullYear(), choosedToDate.getMonth(), choosedToDate.getDate(), 23, 59, 59);

  //   params['toDate'] = toDate.toISOString();
  //   params['fromDate'] = fromDate.toISOString();

  //   super.executeGet(params, success, error, complete);
  // }



  prepareApiParams(params: any, getRowParams: IGetRowsParams) {

    const choosedFromDate = (this.accountingService.reportFromDate$.value as Date) || new Date();
    const fromDate = new Date(choosedFromDate.getFullYear(), choosedFromDate.getMonth(), choosedFromDate.getDate(), 0, 0, 0, 999);

    const choosedToDate = (this.accountingService.reportToDate$.value as Date) || new Date();
    const toDate = new Date(choosedToDate.getFullYear(), choosedToDate.getMonth(), choosedToDate.getDate(), 23, 59, 59);


    params['includeColumnHeader'] = true;
    params['sort_Account'] = 'asc';
    params['groupBy'] = 'Account';
    params['toDate'] = toDate.toISOString();
    params['fromDate'] = fromDate.toISOString();
    return params;
  }

  // initDataSource() {
  //   const source = super.initDataSource();
  //   // Set DataSource: prepareParams
  //   source.prepareParams = (params: any) => {

  //     const choosedFromDate = (this.accountingService.reportFromDate$.value as Date) || new Date();
  //     const fromDate = new Date(choosedFromDate.getFullYear(), choosedFromDate.getMonth(), choosedFromDate.getDate(), 0, 0, 0, 999);

  //     const choosedToDate = (this.accountingService.reportToDate$.value as Date) || new Date();
  //     const toDate = new Date(choosedToDate.getFullYear(), choosedToDate.getMonth(), choosedToDate.getDate(), 23, 59, 59);

  //     // params['reportSummary'] = true;
  //     // params['eq_Accounts'] = '[111,112,128,131,138,141,136,211,331,338,511,512,515,521,632,635,641,642,711,4222,811,156,1331,3331,4212,4111,4112,4118,3341,3411,3348,3349,3350,6411,4211,3412,2288]';
  //     params['includeColumnHeader'] = true;
  //     params['sort_Account'] = 'asc';
  //     params['groupBy'] = 'Account';
  //     params['toDate'] = toDate.toISOString();
  //     params['fromDate'] = fromDate.toISOString();

  //     return params;
  //   };

  //   return source;
  // }

  getList(callback: (list: AccountModel[]) => void) {
    super.getList((rs) => {
      // rs.forEach(item => {
      //   item.Content = item.Content.substring(0, 256) + '...';
      // });
      if (callback) callback(rs);
    });
  }

  /** Config for paging */
  protected configPaging() {
    return {
      display: true,
      perPage: 99999,
    };
  }

  openInstantDetailReport(rowData: any) {
    // this.cms.openDialog(AccountingDetailByObjectReportComponent, {
    let detailReportComponent = null;
    let voucherReportComponent = null;
    let title = 'Báo Cáo Chi Tiết Theo Tài Khoản';
    if (/^511/.test(rowData['Account'])) {
      title = 'Báo Cáo Doanh Thu Hàng Hóa - Dịch Vụ';
    }
    if (/^515/.test(rowData['Account'])) {
      title = 'Báo Cáo Doanh Thu Tài Chính';
    }
    if (/^131/.test(rowData['Account'])) {
      title = 'Báo Cáo Công Nợ Phải Thu Khách Hàng';
      detailReportComponent = AccountingReceivablesFromCustomersDetailsReportPrintComponent;
      voucherReportComponent = AccountingReceivablesFromCustomersVoucherssReportPrintComponent;
    }
    if (/^331/.test(rowData['Account'])) {
      title = 'Công Nợ Phải Trả Nhà Cung Cấp';
      detailReportComponent = AccountingLiabilitiesDetailsReportPrintComponent;
    }
    if (/^111/.test(rowData['Account'])) {
      title = 'Báo Cáo Lưu Chuyển Tiền Mặt - Vàng';
    }
    if (/^112/.test(rowData['Account'])) {
      title = 'Báo Cáo Lưu Chuyển Tiền Gửi';
    }
    if (/^632/.test(rowData['Account'])) {
      title = 'Báo Cáo Giá Vốn Hàng Bán';
    }
    if (/^641/.test(rowData['Account'])) {
      title = 'Báo Cáo Chi Phí Nhân Viên';
    }
    if (/^642/.test(rowData['Account'])) {
      title = 'Báo Cáo Chi Phí Nhân Viên Quản Lý';
    }
    if (/^156/.test(rowData['Account'])) {
      title = 'Báo Cáo Hàng Hóa Tồn Kho';
    }
    this.cms.openDialog(AccountingDetailByObjectReportAgComponent, {
      context: {
        inputMode: 'dialog',
        // object: rowData.Object,
        accounts: [rowData['Account']],
        report: 'reportDetailByAccountAndObject',
        fromDate: null,
        toDate: null,
        title: title,
        detailReportComponent: detailReportComponent,
        voucherReportComponent: voucherReportComponent,
      },
      closeOnEsc: false,
    })
  }

  openFormDialplog(ids?: string[], onDialogSave?: (newData: Model[]) => void, onDialogClose?: () => void): void {
    throw new Error('Method not implemented.');
  }
  async refresh() {
    super.refresh();
  }

}
