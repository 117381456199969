<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="onControlEnter($event)" (keydown.enter)="onControlEnter($event)" class="dialog-wrap popup">
  <div formArrayName="array">
    <div *ngFor="let formItem of array.controls; let i = index">
      <nb-card class="small-header popup" [nbSpinner]="isProcessing">
        <nb-card-header>
          <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('Chiến dịch chiết khấu CTV add-on' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
        </nb-card-header>
        <nb-card-body>
          <div [formGroup]="formItem">

            <div class="row">
              <div class="col-sm-3">
                <ngx-form-group [formGroup]="formItem" name="Title" label="Tên chiến dịch" [array]="array" [index]="i" [allowCopy]="false" [required]="true">
                  <input type="text" nbInput fullWidth formControlName="Title" placeholder="Tên chiến dịch">
                </ngx-form-group>
              </div>
              <div class="col-sm-3">
                <ngx-form-group [formGroup]="formItem" name="Page" label="Collaborator.Page.label" [array]="array" [index]="i" [allowCopy]="false" [required]="true" [touchedValidate]="false">
                  <ngx-select2 formControlName="Page" [select2Option]="select2OptionForPage" [data]="this.collaboratorService.pageList$.value"></ngx-select2>
                </ngx-form-group>
              </div>
              <div class="col-sm-3">
                <ngx-form-group [formGroup]="formItem" name="DateRange" label="Phạm vị áp dụng" [array]="array" [index]="i" [allowCopy]="false" [required]="true">
                  <input type="text" [owlDateTime]="DateRange" [owlDateTimeTrigger]="DateRange" [selectMode]="'range'" nbInput fullWidth formControlName="DateRange" placeholder="{{'Common.dateRange' | translate | headtitlecase}}">
                  <owl-date-time #DateRange></owl-date-time>
                </ngx-form-group>
              </div>
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Code'), 'warning')">
                  <label class="label"><span class="valid">ID</span><span class="invalid">ID (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="Code" maxlength="30" placeholder="Auto...">
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <ngx-ag-dynamic-list [titleNowrap]="true" #details [title]="'DS CTV Bán Hàng'" [columnDefs]="columnDefs" [rowModelType]="'clientSide'" [rowData]="rowData" [idKey]="['Publisher']" (onReady)="onGridReady($event)" (onNodesSelected)="onPublishersSelected($event)" (onComponentInit)="onGridInit($event)" style="display: block; height: 500px"></ngx-ag-dynamic-list>
              </div>
              <div class="col-md-6">
                <ngx-ag-dynamic-list [titleNowrap]="true" #details2 [title]="'DS sản phẩm theo CTV: ' + (productExtendData?.publisher?.PublisherName || '' )" [columnDefs]="productsColumnDefs" [rowModelType]="'clientSide'" [rowData]="rowData" [extendData]="productExtendData" [idKey]="['Product', 'Unit']" (onReady)="onProductGridReady($event)" (onComponentInit)="onProductGridInit($event)" style="display: block; height: 500px"></ngx-ag-dynamic-list>
              </div>
            </div>

          </div>
        </nb-card-body>
        <nb-card-footer>
          <div class="buttons-row" style="text-align: right;">
            <button nbButton status="primary" [disabled]="!form.valid || isProcessing" style="float: right;" hero (click)="saveAndClose()">Lưu & Đóng</button>
            <button nbButton status="success" [disabled]="!form.valid || isProcessing" style="float: right;" hero>Lưu</button>
          </div>
        </nb-card-footer>
      </nb-card>

    </div>
  </div>
</form>