<form class="dialog-wrap popup">
  <nb-card class="small-header popup">
    <nb-card-header>
      <ngx-card-header [size]="size" [icon]="favicon" [title]="title ? title : ('Import data' | translate:{definition: '', action: cms.translate.instant('Common.assign')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
    </nb-card-header>
    <nb-card-body>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <div class="row">
              <div class="col-sm-6">
                <ngx-form-group [hideLabel]="true" [formGroup]="configFormGroup" name="IsMapByProductName" [allowCopy]="false" [required]="false">
                  <nb-checkbox formControlName="IsMapByContactName">Kiểm tra trùng bằng tên liên hệ</nb-checkbox>
                </ngx-form-group>
              </div>
              <div class="col-sm-6">
                <button type="button" nbButton status="danger" hero size="small" [nbSpinner]="processing"
                  (click)="chooseFile.click() && false" [disabled]="processing"
                  title="{{'Common.choose' | translate | headtitlecase}}" style="float: right; margin-bottom: 1rem;">
                  <nb-icon pack="eva" icon="file"></nb-icon>{{'Common.upload' | translate | headtitlecase}}
                </button>
                <input type="file" #chooseFile (change)="onFileChange($event)" style="display: none">
                <button type="button" nbButton status="primary" hero size="small"
                  (click)="exportTemplateFile() && false"
                  title="{{'Download Template' | translate | headtitlecase}}" style="float: right; margin-bottom: 1rem; margin-right: 0.5rem">
                  <nb-icon pack="eva" icon="download-outline"></nb-icon>{{'Download Template' | translate | headtitlecase}}
                </button>
                <button type="button" nbButton status="info" hero size="small"
                  (click)="checkImportAll() && false"
                  title="{{'Download Template' | translate | headtitlecase}}" style="float: right; margin-bottom: 1rem; margin-right: 0.5rem">
                  <nb-icon pack="eva" icon="checkmark-square-outline"></nb-icon>{{'Nhập tất cả' | translate | headtitlecase}}
                </button>
              </div>
            </div>
            <label class="label">{{'Danh sách liên hệ sẽ được import' | translate | headtitlecase}}</label>
            <div class="ag-grid-wrap" style="height: 500px;">
              <!-- <ag-grid-angular #agGrid style="width: 100%; height: 100%;" id="myGrid" class="ag-theme-balham{{themeName ? ('-'+themeName) : ''}}"  [gridOptions]="gridOptions" [modules]="modules" [columnDefs]="columnDefs" [debug]="true" [defaultColDef]="defaultColDef" [rowSelection]="rowSelection" [rowModelType]="rowModelType" [paginationPageSize]="paginationPageSize" [cacheOverflowSize]="cacheOverflowSize" [maxConcurrentDatasourceRequests]="maxConcurrentDatasourceRequests" [infiniteInitialRowCount]="infiniteInitialRowCount" [maxBlocksInCache]="maxBlocksInCache" [cacheBlockSize]="cacheBlockSize" [getRowNodeId]="getRowNodeId" [components]="components" [rowData]="rowData" (gridReady)="onGridReady($event)" [multiSortKey]="multiSortKey" [rowDragManaged]="rowDragManaged" [getRowHeight]="getRowHeight" [rowHeight]="rowHeight" (columnResized)="onColumnResized()" (rowSelected)="onRowSelected()" [pagination]="pagination" [getRowStyle]="getRowStyle" (cellDoubleClicked)="cellDoubleClicked($event)">
              </ag-grid-angular> -->
              <ngx-ag-dynamic-list #agProductList [getRowNodeId]="getRowNodeId" [actionButtonList]="productActionButtonList" [columnDefs]="columnDefs" (onReady)="onGridReady($event)" [rowModelType]="'clientSide'" [components]="components" [rowData]="rowData" [getRowNodeId]="getRowNodeId" [idKey]="['No']" style="display: block; height: 500px"></ngx-ag-dynamic-list>
            </div>
          </div>
        </div>
      </div>
    </nb-card-body>
    <nb-card-footer>
      <nb-progress-bar *ngIf="progress > 0" [value]="progress" [status]="progressStatus" size="tiny" style="margin: 0.5rem;">{{progressLabel}}</nb-progress-bar>
    </nb-card-footer>
    <nb-card-footer>
      <div class="buttons-row" style="text-align: right;">
        <button nbButton status="warning" hero [nbSpinner]="processing">{{'Common.close' | translate | headtitlecase}}</button>
        <button type="button" nbButton status="primary" [disabled]="processing" hero (click)="generateVouchers()" [nbSpinner]="processing">{{'Generate' | translate | headtitlecase}}</button>
        <!--<button type="button" nbButton status="danger" [disabled]="!categoriesFormControl.value || processing" hero (click)="revokeCategories()">{{'Common.revoke' | translate | headtitlecase}}</button> -->
      </div>
    </nb-card-footer>
  </nb-card>
</form>