import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogService, NbToastrService, NbThemeService, NbDialogRef } from '@nebular/theme';
import { AgTextCellRenderer } from '../../../../lib/custom-element/ag-list/cell/text.component';
import { agMakeCommandColDef } from '../../../../lib/custom-element/ag-list/column-define/command.define';
import { agMakeSelectionColDef } from '../../../../lib/custom-element/ag-list/column-define/selection.define';
import { AgSelect2Filter } from '../../../../lib/custom-element/ag-list/filter/select2.component.filter';
import { AgGridDataManagerListComponent } from '../../../../lib/data-manager/ag-grid-data-manger-list.component';
import { Model } from '../../../../models/model';
import { ApiService } from '../../../../services/api.service';
import { CommonService } from '../../../../services/common.service';
import { RootServices } from '../../../../services/root.services';
import { ContractBusinessInfoPrintComponent } from '../business-info-print/business-info-print.component';
import { ColDef, IGetRowsParams } from '@ag-grid-community/core';
import { agMakeStateColDef } from '../../../../lib/custom-element/ag-list/column-define/state.define';
import { ContractStateMap } from '../../contract.state.map';
import { ContractBusinessInfoFormComponent } from '../business-info-form/business-info-form.component';

@Component({
  selector: 'ngx-contract-business-info-list',
  templateUrl: './business-info-list.component.html',
  styleUrls: ['./business-info-list.component.scss']
})
export class ContractBusinessInfoListComponent extends AgGridDataManagerListComponent<Model, ContractBusinessInfoFormComponent> implements OnInit {

  componentName: string = 'ContractBusinessInfoListComponent';
  formPath = '/contract/business-info/form';
  apiPath = '/contract/business-infos';
  idKey = ['SystemUuid'];

  // Use for load settings menu for context
  feature = {
    Module: { id: 'Ecard', text: 'Hợp đồng' },
    Feature: { id: 'Ecard', text: 'Hợp đồng' }
  };

  printDialog = ContractBusinessInfoPrintComponent;
  formDialog = ContractBusinessInfoFormComponent;

  // AG-Grid config
  public rowHeight: number = 50;
  // @Input() suppressRowClickSelection = false;

  @Input() gridHeight = '100%';

  static processingMap = {};

  constructor(
    public rsv: RootServices,
    public apiService: ApiService,
    public router: Router,
    public cms: CommonService,
    public dialogService: NbDialogService,
    public toastService: NbToastrService,
    public themeService: NbThemeService,
    public ref: NbDialogRef<ContractBusinessInfoListComponent>,
    public datePipe: DatePipe,
  ) {
    super(rsv, apiService, router, cms, dialogService, toastService, themeService, ref);

    this.defaultColDef = {
      ...this.defaultColDef,
      cellClass: 'ag-cell-items-center',
    }

    this.pagination = false;
    // this.maxBlocksInCache = 5;
    this.paginationPageSize = 100;
    this.cacheBlockSize = 100;
  }

  async init() {
    const processingMap = ContractStateMap.businessInfoStateMap;
    return super.init().then(async state => {
      await this.cms.waitForLanguageLoaded();
      
      this.columnDefs = this.configSetting([
        {
          ...agMakeSelectionColDef(this.cms),
          headerName: 'ID',
          field: 'Id',
          width: 100,
          valueGetter: 'node.data.Id',
          // sortingOrder: ['desc', 'asc'],
          initialSort: 'desc',
        },
        // {
        //   headerName: 'Mã',
        //   field: 'Code',
        //   width: 140,
        //   filter: 'agTextColumnFilter',
        //   pinned: 'left',
        // },
        {
          headerName: 'Tiêu đề',
          field: 'Title',
          width: 200,
          filter: 'agTextColumnFilter',
          autoHeight: true,
        },
        // {
        //   headerName: 'Loại',
        //   field: 'Type',
        //   width: 200,
        //   filter: 'agTextColumnFilter',
        //   autoHeight: true,
        // },
        {
          headerName: 'Tên doanh nghiệp',
          field: 'Name',
          width: 350,
          filter: 'agTextColumnFilter',
          autoHeight: true,
        },
        {
          headerName: 'Tên rút gọn/Tên tiếng anh',
          field: 'ShortName',
          width: 250,
          filter: 'agTextColumnFilter',
          autoHeight: true,
        },
        {
          headerName: 'Số điện thoại',
          field: 'Phone',
          width: 150,
          filter: 'agTextColumnFilter',
          autoHeight: true,
        },
        {
          headerName: 'Email',
          field: 'Email',
          width: 250,
          filter: 'agTextColumnFilter',
          autoHeight: true,
        },
        {
          headerName: 'Địa chỉ',
          field: 'Address',
          width: 320,
          filter: 'agTextColumnFilter',
          autoHeight: true,
        },
        {
          headerName: 'Đại điện',
          field: 'Delegator',
          width: 180,
          filter: 'agTextColumnFilter',
          autoHeight: true,
        },
        {
          headerName: 'Chúc vụ',
          field: 'DelegatorPosition',
          width: 150,
          filter: 'agTextColumnFilter',
          autoHeight: true,
        },
        {
          headerName: 'Ngân hàng',
          field: 'BankName',
          width: 250,
          filter: 'agTextColumnFilter',
          autoHeight: true,
        },
        {
          headerName: 'STK Ngân hàng',
          field: 'BankAccount',
          width: 150,
          filter: 'agTextColumnFilter',
          autoHeight: true,
        },
        {
          ...agMakeStateColDef(this.cms, processingMap, (data) => {
              this.preview([data]);
          }),
          headerName: 'Trạng thái',
          field: 'State',
          width: 300,
        },
        {
          ...agMakeCommandColDef(this, this.cms, true, true, true),
        }
      ] as ColDef[]);

      return state;
    });
  }

  ngOnInit() {
    super.ngOnInit();
  }

  // @Input() getRowHeight = (params: RowHeightParams<CommercePosOrderModel>) => {
  //   return 123;
  // }

  prepareApiParams(params: any, getRowParams: IGetRowsParams) {
    return params;
  }

  /** Implement required */
  openFormDialplog(ids?: string[], onDialogSave?: (newData: Model[]) => void, onDialogClose?: () => void) {
    this.cms.openDialog(ContractBusinessInfoFormComponent, {
      context: {
        inputMode: 'dialog',
        inputId: ids,
        onDialogSave: (newData: Model[]) => {
          if (onDialogSave) onDialogSave(newData);
        },
        onDialogClose: () => {
          if (onDialogClose) onDialogClose();
        },
      },
    });
    return false;
  }

  onGridReady(params) {
    super.onGridReady(params);
  }

}
